import React, { useState, useEffect } from "react";

/* Importing TV Menu CSS */
import "../tvmenu-styles.css";

/* Importing Google Fonts */
import GoogleFontLoader from "react-google-font";

/* Importing React Bootstrap */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

/* Importing React Bootstrap CSS */
import "bootstrap/dist/css/bootstrap.min.css";

/* Importing Fetch Toast Menu Data */
import { fetchToastMenuData } from "../api";

/* Importing Render Menu Items */
import { renderToastMenuItems } from "../renderMenuItems";

const Page1 = () => {
  const [menu, setMenu] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchMenu = async () => {
      setIsLoading(true);
      const data = await fetchToastMenuData();
      setMenu(data);
      setIsLoading(false);
    };
  
    fetchMenu();
  
    const timer = setInterval(() => {
      fetchMenu();
    }, 300000);
  
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <Container fluid style={{ margin: "6px" }}>
            <GoogleFontLoader
                fonts={[
                    {
                        font: "Lobster",
                    },
                    {
                        font: "Bree Serif",
                    },
                ]}
            />
          <Row>
            <Col>
              <h2 className="cat-title" style={{ fontFamily: "Lobster" }}>
                Snacks
              </h2>
              <h5 style={{ fontFamily: "Lobster", marginLeft: "-4px", marginTop: "-8px", fontSize: "34px", color: "rgb(1, 137, 0)" }}>(Available from 5 PM)</h5>
              {renderToastMenuItems(menu, "Snacks (Available from 5 PM)")}
              <h2 className="cat-title" style={{ fontFamily: "Lobster" }}>
                Tandoor
              </h2>
              {renderToastMenuItems(menu, "Tandoor")}
              <h2 className="cat-title" style={{ fontFamily: "Lobster" }}>
                Bakery
              </h2>
              {renderToastMenuItems(menu, "Bakery")}
            </Col>
            <Col>
            <h2 className="cat-title" style={{ fontFamily: "Lobster" }}>
                Chaat
              </h2>
              {renderToastMenuItems(menu, "Chaat")}
              <h2 className="cat-title" style={{ fontFamily: "Lobster" }}>
                Desserts
              </h2>
              {renderToastMenuItems(menu, "Desserts")}
            </Col>
            <Col>
            <h2 className="cat-title" style={{ fontFamily: "Lobster" }}>
                Street Style
              </h2>
              {renderToastMenuItems(menu, "Street Style")}
              <h2 className="cat-title" style={{ fontFamily: "Lobster" }}>
                Drinks
              </h2>
              {renderToastMenuItems(menu, "Drinks")}
              </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default Page1;