import React, { useState, useEffect } from 'react';
import { Button, Table, Modal, message, Alert, Form, Input, Layout } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import OrderModal from './OrderModal';
import OrderTable from './OrderTable';
import api from '../services/api';
import oldLogo from "../assets/logo-old.png";
import { Helmet } from 'react-helmet';
import "../assets/css/dashboard.css"

const { Content, Header } = Layout;

const Dashboard = () => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (isAuthenticated) {
      fetchOrders();
    }
  }, [isAuthenticated]);

  const fetchOrders = async () => {
    try {
      setIsLoading(true);
      const response = await api.get('/partyorders/list');
      setOrders(response.data.result);
    } catch (error) {
      console.error('Error fetching orders:', error);
      message.error('Failed to fetch orders');
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   fetchOrders();
  // }, []);

  // const handleDeleteOrder = async (orderId) => {
  //   try {
  //     await api.delete(`/partyorders/delete/${orderId}`);
  //     message.success('Order deleted successfully');
  //     fetchOrders();
  //   } catch (error) {
  //     console.error('Error deleting order:', error);
  //     message.error('Failed to delete order');
  //   }
  // };

  const login = () => {
    const currentYear = new Date().getFullYear();
    if (username === 'Saravanan' && password === 'TSS@2024') {
      setIsAuthenticated(true);
      message.success('Login successful');
    } else {
      message.error('Invalid username or password');
    }
  };

  if (!isAuthenticated) {
    return (
      <Layout style={{ minHeight: '100vh', justifyContent: 'center', alignItems: 'center' }}>
        <Form
          style={{ width: '300px' }}
          onFinish={login}
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input onChange={(e) => setUsername(e.target.value)} />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password onChange={(e) => setPassword(e.target.value)} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Login
            </Button>
          </Form.Item>
        </Form>
      </Layout>
    );
  }

  const logout = () => {
    setIsAuthenticated(false);
  };

  const handleDeleteOrder = async (orderId) => {
    try {
      const confirmed = await askForConfirmation(); // Prompt the user for confirmation
      if (!confirmed) return; // If not confirmed, exit the function

      await api.delete(`/partyorders/delete/${orderId}`);
      message.success('Order deleted successfully');
      fetchOrders();
    } catch (error) {
      console.error('Error deleting order:', error);
      message.error('Failed to delete order');
    }
  };

  const askForConfirmation = () => {
    return new Promise((resolve) => {
      const pin = prompt('Please enter your PIN or password to confirm deletion:');
      if (pin === 'TSS@2023') {
        resolve(true); // PIN/password is correct
      } else {
        message.error('Invalid password'); // Show error message
        resolve(false); // PIN/password is incorrect
      }
    });
  };

  const handleEditOrder = (order) => {
    setSelectedOrder(order);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setSelectedOrder(null);
    setIsModalVisible(false);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Helmet>
        <title>Party Orders - Admin | Nashua</title>
      </Helmet>
      <Header style={{ background: '#fff', padding: '0 16px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          {/* Logo on the left */}
          <div>
            <img src={oldLogo} alt="Logo" style={{ height: '40px', marginRight: '16px' }} />
          </div>

          {/* Buttons on the right */}
          <div>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={() => setIsModalVisible(true)}
              style={{ marginRight: '10px' }} // Optional: Adds some space between the two buttons
            >
              Create New Order
            </Button>
            <Button
              type="primary"
              onClick={logout}
            >
              Logout
            </Button>
          </div>
        </div>
      </Header>

      <Content style={{ margin: '16px' }}>
        {orders.length === 0 && (
          <Alert message="No orders found" type="info" style={{ marginBottom: '16px' }} />
        )}
        <div className="responsive-table-container">
          <OrderTable
            orders={orders}
            isLoading={isLoading}
            onDelete={handleDeleteOrder}
            onEdit={handleEditOrder}
          />
        </div>
        <Modal
          title={selectedOrder ? 'Edit Order' : 'Create Order'}
          visible={isModalVisible}
          onCancel={handleModalClose}
          footer={null}
          destroyOnClose
        >
          <OrderModal
            order={selectedOrder}
            onClose={handleModalClose}
            onOrderCreated={fetchOrders}
            onOrderUpdated={fetchOrders}
          />
        </Modal>
      </Content>
    </Layout>
  );
};

export default Dashboard;
