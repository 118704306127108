import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, message, Select, InputNumber } from "antd";
import axios from "axios";

const { Option } = Select;

const InventoryForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [unitDescription, setUnitDescription] = useState("");

  const onFinish = (values) => {
    setLoading(true);
    if (!values.iQuantity) {
      values.iQuantity = 0;
    }    
    axios
      .post("https://chowrastha-admin-panel.azurewebsites.net/api/inventory/create", values)
      .then((res) => {
        setLoading(false);
        message.success("Item created successfully");
        navigate("/inventory");
      })
      .catch((err) => {
        setLoading(false);
        message.error("Error creating item");
      });
  };

  const handleQuantityChange = (value) => {
    if (value <= 2) {
      message.warning("Reached Limit. Make an Order");
    }
  };

  const handleUnitChange = (value) => {
    const unitDescriptions = {
      Ct: `It represents the number of individual items in a package. For example, "100 Count" means there are 100 items in the package.`,
      Gm: ``,
      Kg: ``,
      Pound: `It is a unit of mass in the US customary system, equivalent to 16 ounces.`,
      Qt: `It is a unit of volume in the US customary system, used for liquids. There are "4 quarts" in a gallon. For example, "1 Quart" means there is 1 quart of the product.`,
      Gal: `It is a unit of volume in the US customary system, used for liquids. For example, "1 Gallon" means there is 1 gallon of the product.`,
      "Sq. ft": `It is a unit of area, used for measuring the size of a flat surface like a roll of aluminum foil or plastic food wrap. For example, "500 Sq. ft" means there are 500 square feet of material in the package.`,
    };

    setUnitDescription(unitDescriptions[value]);
  };

  return (
    <div>
      <h1>Add Inventory Item</h1>
      <Form layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Name"
          name="iName"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Category"
          name="iCategory"
        >
          <Select placeholder="Select a category">
            <Option value="Condiment Supply">Condiment Supply</Option>
            <Option value="Front Desk">Front Desk</Option>
            <Option value="Kitchen Area">Kitchen Area</Option>
            <Option value="Common">Common</Option>
            <Option value="Chaat">Chaat</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Quantity"
          name="iQuantity"
        >
          <InputNumber
            // min={2}
            onChange={handleQuantityChange}
          />
        </Form.Item>
        <Form.Item
          label="Unit"
          name="iUnit"
        >
          <Select placeholder="Select a unit" onChange={handleUnitChange}>
            <Option value="Ct">ct</Option>
            <Option value="Gm">gms</Option>
            <Option value="Kg">kgs</Option>
            <Option value="Pound">lb</Option>
            <Option value="Qt">qt</Option>
            <Option value="Gal">gal</Option>
            <Option value="Sq. ft">sq. ft</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Unit Description">
          <Input value={unitDescription} disabled />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Create Item
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default InventoryForm;