import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Descriptions, Button, message } from "antd";
import axios from "axios";

const InventoryDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(null);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://chowrastha-admin-panel.azurewebsites.net/api/inventory/read/${id}`)
      .then((res) => {
        setItem(res.data);
        setLoading(false);
      })
      .catch((err) => {
        message.error("Error fetching item details");
        setLoading(false);
      });
  }, [id]);

  const deleteItem = () => {
    setLoading(true);
    axios
      .delete(`https://chowrastha-admin-panel.azurewebsites.net/api/inventory/delete/${id}`)
      .then((res) => {
        setLoading(false);
        message.success("Item deleted successfully");
        navigate("/inventory");
      })
      .catch((err) => {
        setLoading(false);
        message.error("Error deleting item");
      });
  };

  return (
    <div>
      <h1>Item Details</h1>
      {loading ? (
        "Loading..."
      ) : (
        item && (
          <div>
            <Descriptions layout="vertical" bordered>
              <Descriptions.Item label="Name">{item.iName}</Descriptions.Item>
              <Descriptions.Item label="Category">
                {item.iCategory}
              </Descriptions.Item>
              <Descriptions.Item label="Quantity">
                {item.iQuantity}
              </Descriptions.Item>
            </Descriptions>
            <Button
              type="primary"
              style={{ marginTop: "10px" }}
              onClick={deleteItem}
              loading={loading}
            >
              Delete Item
            </Button>
          </div>
        )
      )}
    </div>
  );
};

export default InventoryDetails;
