import React, { useState, useEffect } from "react";

/* Importing TV Menu CSS */
import "../tvmenu-styles.css";

/* Importing Google Fonts */
import GoogleFontLoader from "react-google-font";

/* Importing React Bootstrap */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

/* Importing React Bootstrap CSS */
import "bootstrap/dist/css/bootstrap.min.css";

/* Importing Fetch Toast Menu Data */
import { fetchToastMenuData } from "../api";

/* Importing Render Menu Items */
import { renderToastMenuItems } from "../renderMenuItems";

const Page2 = () => {
  const [menu, setMenu] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchMenu = async () => {
      setIsLoading(true);
      const data = await fetchToastMenuData();
      setMenu(data);
      setIsLoading(false);
    };
  
    fetchMenu();
  
    const timer = setInterval(() => {
      fetchMenu();
    }, 300000);
  
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <Container fluid style={{ margin: "6px" }}>
            <GoogleFontLoader
                fonts={[
                    {
                        font: "Lobster",
                    },
                    {
                        font: "Bree Serif",
                    },
                ]}
            />
          <Row>
            <Col>
              <h2 className="cat-title" style={{ fontFamily: "Lobster" }}>
                Dosa
              </h2>
              {renderToastMenuItems(menu, "Dosa")}
            </Col>
            <Col>
            <h2 className="cat-title" style={{ fontFamily: "Lobster" }}>
                Tiffins
              </h2>
              {renderToastMenuItems(menu, "Tiffins")}
              <h2 className="cat-title" style={{ fontFamily: "Lobster" }}>
                Breads
              </h2>
              {renderToastMenuItems(menu, "Breads")}
              <h2 className="cat-title" style={{ fontFamily: "Lobster" }}>
                Sides
              </h2>
              {renderToastMenuItems(menu, "Sides")}
            </Col>
            <Col>
            <h2 className="cat-title" style={{ fontFamily: "Lobster" }}>
                Non-Veg Curries
              </h2>
              {renderToastMenuItems(menu, "Non-Veg  Curries")}
              </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default Page2;