import React, { useEffect, useState } from "react";

/* Importing TV Menu CSS */
import "../tvmenu-styles.css";

/* Importing Google Fonts */
import GoogleFontLoader from "react-google-font";

/* Importing Ant Design */
import { notification } from "antd";

/* Importing React Bootstrap */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

/* Importing React Bootstrap CSS */
import "bootstrap/dist/css/bootstrap.min.css";

/* Importing Render Menu Items */
import { renderToastMenuItems } from "../renderMenuItems";

/* Importing useFetchData Hook */
import useFetchData from "./hooks/useFetchData";

/* Importing Menu Carousel */
import MenuCarousel from "./components/MenuCarouselComponent/menuCarousel";

const Page4 = () => {
  const { menu, fillerData, menuData, isLoading } = useFetchData();

  const [orderNum, setOrderNum] = useState(null);

  useEffect(() => {
    // Fetch data from the notification API
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://chowrastha-client-backend.azurewebsites.net/orders/notification"
        );
        const data = await response.json();

        if (data.length > 0) {
          setOrderNum(data[0].orderNum);
          showNotification(data[0].orderNum);
        } else {
          setOrderNum(null);
        }
      } catch (error) {
        console.error("Error fetching notification data:", error);
      }
    };

    // Fetch data initially and set an interval to fetch data every 20 seconds
    fetchData();
    const interval = setInterval(fetchData, 20000);

    // Clear the interval when the component is unmounted
    return () => {
      clearInterval(interval);
    };
  }, []);

  const showNotification = (orderNum) => {
    // Show a success notification with the order number
    notification.success({
      message: `Order #${orderNum} is ready for pickup`,
      duration: 10,
    });

    // Speak the notification text after a delay
    setTimeout(() => {
      const speechText = `Order number ${orderNum} is ready for pickup. I repeat, Order number ${orderNum} is ready for pickup.`;
      speakText(speechText);
    }, 1000);
  };

  const speakText = (text) => {
    if ("speechSynthesis" in window) {
      const voices = window.speechSynthesis.getVoices();
      const voice = voices.find(
        (v) => v.voiceURI === "Google UK English Female"
      );

      if (voice) {
        // Create a SpeechSynthesisUtterance with the appropriate voice and text
        const speech = new SpeechSynthesisUtterance();
        speech.lang = "en-US";
        speech.volume = 1;
        speech.rate = 1;
        speech.pitch = 1;
        speech.voice = voice;
        speech.text = convertNumberToSpokenWord(text);

        // Speak the text using speech synthesis
        window.speechSynthesis.speak(speech);
      } else {
        console.warn("UK English Female voice not found.");
      }
    } else {
      console.warn("SpeechSynthesis API is not supported in this browser.");
    }
  };

  const convertNumberToSpokenWord = (text) => {
    const numberPattern = /\d+/g;
    const numbers = text.match(numberPattern);

    if (numbers) {
      numbers.forEach((number) => {
        const spokenWord = number
          .split("")
          .map((digit) => {
            const spokenDigit = {
              0: "zero",
              1: "one",
              2: "two",
              3: "three",
              4: "four",
              5: "five",
              6: "six",
              7: "seven",
              8: "eight",
              9: "nine",
            };

            return spokenDigit[digit];
          })
          .join(" ");

        text = text.replace(number, spokenWord);
      });
    }

    return text;
  };

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <Container fluid style={{ margin: "6px" }}>
          <GoogleFontLoader
            fonts={[
              {
                font: "Lobster",
              },
              {
                font: "Bree Serif",
              },
            ]}
          />
          <Row>
            <Col lg={6}>
              <h2 className="cat-title" style={{ fontFamily: "Lobster" }}>
                Biryani's
              </h2>
              {renderToastMenuItems(menu, "Biryani's")}
              {/* <h2 className="cat-title" style={{ fontFamily: "Lobster" }}>
                Lunch Combo - Weekdays Only
              </h2>
              {renderToastMenuItems(menu, "Lunch Combo - Weekdays Only")} */}
              <h2 className="cat-title" style={{ fontFamily: "Lobster" }}>
                Average Waiting Time
              </h2>
              {fillerData && (
                <div className="filler-data">
                  <h4 style={{ fontFamily: "Bree Serif" }}>
                    {/* {fillerData[0].averageWaitingTime} */}
                    30 Minutes
                  </h4>
                </div>
              )}
            </Col>
            {/* <Col lg={6}>
              <h2 className="cat-title" style={{ fontFamily: "Lobster" }}>
                Our Signature Dishes
              </h2>
              <MenuCarousel menuData={menuData} />
              <h2 className="cat-title" style={{ fontFamily: "Lobster" }}>
                We Take Party Orders
              </h2>
              {fillerData && (
                <div className="filler-data">
                  <h4 style={{ fontFamily: "Bree Serif" }}>{fillerData[0].fillerNews}</h4>
                </div>
              )}
            </Col> */}
            <Col lg={6}>
              <div className="text-center">
                {/* {fillerData && (
                  <img
                    src={fillerData[0].imageUrl}
                    alt="DC Logo"
                  />
                )} */}
                <img src="https://i.ibb.co/ySm2LzH/dc-logo.png" alt="DC Logo" />
                {/* <img src="https://i.ibb.co/mq7jMtR/441313530-431815303127007-619553011775955993-n.jpg" /> */}
              </div>
              {/* <h2 className="cat-title" style={{ fontFamily: "Lobster" }}>
                Our Signature Dishes
              </h2>
              <MenuCarousel menuData={menuData} /> */}
              <h2
                className="cat-title"
                style={{ fontFamily: "Lobster", marginTop: 60 }}
              >
                We Take Party Orders
              </h2>
              {fillerData && (
                <div className="filler-data">
                  {/* <h4 style={{ fontFamily: "Bree Serif" }}>{fillerData[0].partyOrdersMessage}</h4> */}
                  <h4 style={{ fontFamily: "Bree Serif" }}>Host your next big event with ease! Our restaurant specializes in catering to large groups and parties, offering customizable menus to suit all your celebration needs.</h4>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default Page4;
