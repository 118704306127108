import React, { useState } from 'react';
import './index.css';
import Home from '../../sections/Home';
import Contact from '../../sections/Contact';
import { Navbar, Nav, Button, Container, Row, Col } from 'react-bootstrap';
import { BsDownload } from 'react-icons/bs';

function ResponsiveHeader() {
  const [selectedKey, setSelectedKey] = useState('1');

  const handleSelect = (selectedKey) => {
    setSelectedKey(selectedKey);
  };

  return (
    <>
      <Navbar className='responsive-header-nav' style={{ backgroundColor: "#fff", width: "100%" }} expand="lg">
        <Container fluid>
          <Row style={{ width: '100%', alignItems: 'center' }}>
            <Col md={2}>
              <Navbar.Brand href="#home">
                <img className="header-logo" src="https://iili.io/HeKYJkB.png" alt="Logo" style={{ margin: 16 }} />
              </Navbar.Brand>
            </Col>
            <Col md={8} style={{ display: 'flex', justifyContent: 'center' }}>
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto" activeKey={selectedKey} onSelect={handleSelect}>
                  <Nav.Link eventKey="1" href="#home" active={selectedKey === '1'} className={selectedKey === '1' ? "custom-nav-link active-link" : "custom-nav-link"}>
                    Home
                  </Nav.Link>
                  <Nav.Link eventKey="2" href="#contact" active={selectedKey === '2'} className={selectedKey === '2' ? "custom-nav-link active-link" : "custom-nav-link"}>
                    Contact
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Col>
            <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <a href="https://firebasestorage.googleapis.com/v0/b/desichowrastha-nashua-appcloud.appspot.com/o/DesiChowrastha%20Nashua%2FMenu%2FNashua%20Menu.pdf?alt=media&token=609aa22c-7a5e-456d-8144-34fd99bbd100" download>
                <Button variant="success" className="download-btn"><BsDownload /> Download Menu</Button>
              </a>
            </Col>
          </Row>
        </Container>
      </Navbar>
      <div style={{ margin: 24 }} className='dc-nashua-sections'>
        {selectedKey === '1' && <Home />}
        {selectedKey === '2' && <Contact />}
      </div>
    </>
  );
}

export default ResponsiveHeader;