import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ResponsiveHeader from './components/ResponsiveHeader';
import Footer from './components/Footer';

const NashuaLandingPageApp = () => {
  useEffect(() => {
    const currentHostname = window.location.hostname;

    if (currentHostname === "nashua-desichowrastha.web.app") {
      window.location.replace("https://desichowrastha-nashua.web.app/");
    }
  }, []);

  return (
    <div className="App">
      <ResponsiveHeader />
      <Footer />
    </div>
  );
};

export default NashuaLandingPageApp;