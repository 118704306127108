import React, { useState } from "react";
import {
  Table,
  Button,
  Popconfirm,
  Dropdown,
  Menu,
  Modal,
  DatePicker,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  MenuOutlined,
  InfoCircleOutlined,
  SyncOutlined,
  FilePdfOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { saveAs } from "file-saver";
import { ExportToCsv } from "export-to-csv";
import html2pdf from "html2pdf.js";
import dcLogo from "../assets/dc-nashua-logo.webp"
import eSignature from "../assets/eSignature.png"
import reviewQR from "../assets/qr-code.png"
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";

const { RangePicker } = DatePicker;

const OrderTable = ({ orders, isLoading, onDelete, onEdit }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [filterDateRange, setFilterDateRange] = useState([]);
  const [todaysOrdersVisible, setTodaysOrdersVisible] = useState(false);

  const handleMenuClick = (key, order) => {
    if (key === "edit") {
      const { cInvoiceNumber, ...editedOrder } = order;
       onEdit(editedOrder);
    } else if (key === "delete") {
      onDelete(order._id);
    } else if (key === "viewDetails") {
      setModalData(order.cPartyOrderItems);
      setModalVisible(true);
    } else if (key === "invoice") {
      generateInvoice(order);
    } else if (key === "shareInvoice") {
      shareInvoice(order);
    }
  };

  const handleViewDetails = (order) => {
    setModalData(order.cPartyOrderItems);
    setModalVisible(true);
  };

  const handleFilterSubmit = () => {
    // Apply your filter logic here and update the filteredOrders state
    // For example, filtering orders between selected dates
    const filtered = orders.filter((order) => {
      const orderDate = new Date(order.cOrderDate);
      const fromDate = new Date(filterDateRange[0]);
      const toDate = new Date(filterDateRange[1]);
      return orderDate >= fromDate && orderDate <= toDate;
    });
    setFilteredOrders(filtered);
  };

  const handleTodayOrders = () => {
    const currentDate = moment().format("YYYY-MM-DD");
    const filtered = orders.filter((order) => {
      const orderDate = moment(order.cPartyDate).format("YYYY-MM-DD");
      return orderDate === currentDate;
    });
    setFilteredOrders(filtered);
    setTodaysOrdersVisible(true);
  };

  const generateCSV = () => {
    const options = {
      filename: "filtered_orders.csv",
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
  
    const csvData = filteredOrders.map((order) => {
      const combinedPayments = order.cPartyOrderPaymentDetails.reduce(
        (acc, payment) => {
          const paymentMode = payment.paymentMode;
          const amountPaid = payment.amountPaid;
          if (acc.hasOwnProperty(paymentMode)) {
            acc[paymentMode] += amountPaid;
          } else {
            acc[paymentMode] = amountPaid;
          }
          return acc;
        },
        {}
      );
  
      return {
        "Customer Name": order.cName,
        "Phone Number": order.cPhoneNumber,
        "Order Date": order.cOrderDate,
        "Party Date": order.cPartyDate,
        "Order Status": order.cPartyOrderStatus,
        "Payment Status": order.cPartyOrderPaymentStatus,
        Discount: order.cOrderDiscount,
        "Order Total": order.cOrderTotal,
        "Amount Paid": order.cPartyOrderPaymentDetails.reduce(
          (acc, payment) => acc + payment.amountPaid,
          0
        ),
        Cash: combinedPayments["CASH"] || 0,
        Zelle: combinedPayments["ZELLE"] || 0,
        "Amount Due": order.cAmountDue,
      };
    });
  
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(csvData);
  };  

  const handleDateRangeChange = (dates) => {
    setFilterDateRange(dates);
  };

  const renderActions = (_, order) => (
    <>
      <Button
        type="dashed"
        icon={<InfoCircleOutlined />}
        style={{ marginRight: 8 }}
        onClick={() => handleViewDetails(order)}
      />
      <Dropdown overlay={renderMenu(order)} trigger={["click"]}>
        <Button type="dashed">
          <MenuOutlined />
        </Button>
      </Dropdown>
    </>
  );

  const renderMenu = (order) => (
    <Menu onClick={({ key }) => handleMenuClick(key, order)}>
      <Menu.Item key="invoice">
        <Button type="link" icon={<FilePdfOutlined />} style={{ padding: 0 }}>
          Generate Invoice
        </Button>
      </Menu.Item>
      <Menu.Item key="shareInvoice">
        <Button type="link" icon={<WhatsAppOutlined/>} style={{ padding: 0 }}>
          Share Invoice
        </Button>
      </Menu.Item>
      <Menu.Item key="edit">
        <Button type="link" icon={<EditOutlined />} style={{ padding: 0 }}>
          Edit
        </Button>
      </Menu.Item>
      <Menu.Item key="delete">
        <Popconfirm
          title="Are you sure you want to delete this order?"
          onConfirm={() => handleMenuClick("delete", order)}
          okText="Yes"
          cancelText="No"
        >
          <Button
            type="link"
            danger
            icon={<DeleteOutlined />}
            style={{ padding: 0 }}
          >
            Delete
          </Button>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  const shareInvoice = async (order) => {
    const phoneNumber = order.cPhoneNumber;
    const invoiceMessage = `Here is your invoice:`;
  
    const invoiceData = {
      invoiceNumber: order.cInvoiceNumber,
      orderDate: order.cOrderDate,
      customerName: order.cName,
      phoneNumber: order.cPhoneNumber,
      partyOrderItems: order.cPartyOrderItems, // Pass the order item data
      partyDate: order.cPartyDate,
      orderDiscount: order.cOrderDiscount,
      orderTotal: order.cOrderTotal,
    };
  
    const invoiceTemplate = `
    <!DOCTYPE html>
    <html>
    <head>
        <title>${invoiceData.customerName + "_" + invoiceData.invoiceNumber}</title>
        <style>
            body {
                font-family: Arial, sans-serif;
                margin: 0;
                padding: 20px;
                background-color: #fff;
            }
            
            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 30px;
            }
            
            .header img {
                max-width: 150px;
            }
            
            .header h1 {
                margin: 0;
                font-size: 28px;
            }
            
            .header p {
                margin: 0;
                font-size: 16px;
                color: #555;
            }

            .bill-to-txt {
              color: #708090;
            }
            
            .customer-info {
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;
                margin-top: -20px;
            }
            
            .customer-info h4 {
                margin: 0;
                font-size: 16px;
                color: #555;
            }
            
            .customer-info .left-info {
                text-align: left;
            }
            
            .customer-info .right-info {
                text-align: right;
            }
            
            .table-container {
                margin-bottom: 30px;
            }
            
            table {
                width: 100%;
                border-collapse: collapse;
            }
            
            table th {
                background-color: #708090;
                color: white;
                text-align: center;
            }
            
            table td {
                padding: 12px;
                border: 1px solid #ddd;
                text-align: center;
            }
            
            table td:last-child {
                text-align: left;
            }
            
            .discount {
                margin-bottom: 10px;
                font-size: 16px;
                color: #555;
            }
            
            .order-total {
                margin-bottom: 10px;
                font-size: 18px;
                color: #555;
            }
            
            .signature-field {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;
            }
            
            .signature-field img {
                max-width: 200px;
            }
            
            .qr-code {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-bottom: 20px;
            }
            
            .qr-code img {
                max-width: 150px;
            }
            
            .thanks-message {
                text-align: center;
                margin-bottom: 20px;
                font-size: 16px;
                color: #555;
            }
            
            .footer {
                background-color: #333;
                color: #fff;
                padding: 20px;
                text-align: center;
                font-size: 14px;
            }
            
            .footer p {
                margin: 5px 0;
            }
        </style>
    </head>
    <body>
        <div class="header">
            <img src="https://i.ibb.co/r6mBFrS/dc-nashua-logo.webp" alt="Logo">
            <div>
                <h5>274 Daniel Webster Hwy,<br/><br/>Nashua, NH 03060, US<br/><br/>(+1) 603-722-0770</h5>
            </div>
        </div>
        
        <div class="invoice-info">
            <p><strong>Invoice No:</strong> #${invoiceData.invoiceNumber}</p>
        </div>

        <p class="bill-to-txt"><strong>Bill To:</strong></p>
        
        <div class="customer-info">
            <div class="left-info">
                <p><strong>${invoiceData.customerName}</strong></p>
                <p><strong>(+1) ${invoiceData.phoneNumber}</strong></p>
            </div>
            <div class="right-info">
                <p><strong>Order Date:</strong> ${invoiceData.orderDate}</p>
                <p><strong>Party Date:</strong> ${invoiceData.partyDate}</p>
            </div>
        </div>
        
        <div class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>Item Name</th>
                        <th>Quantity</th>
                        <th>Tray Type</th>
                        <th>Spice Level</th>
                        <th>Item Comments</th>
                        <th>Price</th>
                    </tr>
                </thead>
                <tbody>
                    ${invoiceData.partyOrderItems
                        .map(
                            (item) => `
                                <tr>
                                    <td>${item.itemName}</td>
                                    <td>${item.qty}</td>
                                    <td>${item.trayType}</td>
                                    <td>${item.spiceLevel}</td>
                                    <td>${item.itemComments ? item.itemComments : '-'}</td>
                                    <td style="text-align: left;">$ ${item.price}</td>
                                </tr>
                            `
                        )
                        .join("")}
                    <tr>
                        <td colspan="5" style="text-align: left;"><strong>Total</strong></td>
                        <td style="text-align: left;">$ ${invoiceData.partyOrderItems.reduce((total, item) => total + item.price, 0)}</td>
                    </tr>
                    ${
                        invoiceData.orderDiscount > 0
                            ? `
                    <tr>
                        <td colspan="5" style="text-align: left;"><strong>Discount</strong></td>
                        <td style="text-align: left;">$ -${(invoiceData.orderDiscount / 100) * invoiceData.partyOrderItems.reduce((total, item) => total + item.price, 0)}</td>
                    </tr>
                    <tr>
                        <td colspan="5" style="text-align: left;"><strong>Grand Total</strong></td>
                        <td style="text-align: left;">$ ${invoiceData.partyOrderItems.reduce((total, item) => total + item.price, 0) - (invoiceData.orderDiscount / 100) * invoiceData.partyOrderItems.reduce((total, item) => total + item.price, 0)}</td>
                    </tr>
                    `
                            : ""
                    }
                </tbody>
            </table>
        </div>
        
        <div class="signature-field">
            <div>
                <p>Authorized Signature:</p>
                <img src="https://i.ibb.co/6mBHxGX/e-Signature.png" alt="Authorized Signature">
            </div>
            <div class="qr-code">
                <img src="https://i.ibb.co/LRqVj17/qr-code.png" alt="QR Code">
            </div>
        </div>
        
        <div class="thanks-message">
            <p>Thank you for choosing our restaurant for your dining experience! We appreciate your order and hope you enjoyed our delicious food. We look forward to serving you again soon.</p>
        </div>
        
        <div class="footer">
            <p>For any inquiries, please contact us at chowrastha.nh@mypi.llc</p>
        </div>
    </body>
    </html>
`;
  
const invoiceBlob = new Blob([invoiceTemplate], { type: "text/html" });

const BITLY_ACCESS_TOKEN = "98d137103bfb7d11a6169c09c6d19639c7e47e1b";

const shortenURL = async (url) => {
    const result = await fetch("https://api-ssl.bitly.com/v4/shorten", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${BITLY_ACCESS_TOKEN}`
        },
        body: JSON.stringify({ long_url: url }),
    });

    if (!result.ok) {
        throw new Error('Failed to shorten URL');
    }

    const data = await result.json();
    return data.link;  // This is the shortened URL
};

try {
    // Upload the invoice PDF to Firebase Storage
    const storageRef = ref(storage, `invoices/invoice_nashua_${order._id}.pdf`);
    await uploadBytes(storageRef, invoiceBlob);

    // Get the download URL of the uploaded PDF
    const longDownloadURL = await getDownloadURL(storageRef);

    // Shorten the URL
    const downloadURL = await shortenURL(longDownloadURL);

    // Create the WhatsApp message with the invoice URL
    const whatsappMessage = `${invoiceMessage} ${downloadURL}`;

    // Share the invoice URL via WhatsApp
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
        whatsappMessage
    )}`;
    window.open(whatsappURL);
} catch (error) {
    console.error("Error uploading the invoice PDF:", error);
    // Handle the error
}
};

  const columns = [
    {
      title: "Invoice #",
      dataIndex: "cInvoiceNumber",
      key: "cInvoiceNumber",
    },
    {
      title: "Customer Name",
      dataIndex: "cName",
      key: "cName",
    },
    {
      title: "Phone Name",
      dataIndex: "cPhoneNumber",
      key: "cPhoneNumber",
      render: (cPhoneNumber) => (
        <a
          href={`https://wa.me/+91${cPhoneNumber}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {cPhoneNumber}
        </a>
      ),
    },
    {
      title: "Order Date",
      dataIndex: "cOrderDate",
      key: "cOrderDate",
      sorter: (a, b) => {
      const dateA = moment(a.cOrderDate);
      const dateB = moment(b.cOrderDate);
      return dateA - dateB;
    },
    sortDirections: ["ascend", "descend"],
    },
    {
      title: "Party Date",
      dataIndex: "cPartyDate",
      key: "cPartyDate",
      sorter: (a, b) => {
      const dateA = moment(a.cPartyDate);
      const dateB = moment(b.cPartyDate);
      return dateA - dateB;
    },
    sortDirections: ["ascend", "descend"],
    },
    {
      title: "Delivery Time",
      dataIndex: "cOrderDeliveryTime",
      key: "cOrderDeliveryTime",
    },
    {
      title: "Order Status",
      dataIndex: "cPartyOrderStatus",
      key: "cPartyOrderStatus",
    },
    {
      title: "Payment Status",
      dataIndex: "cPartyOrderPaymentStatus",
      key: "cPartyOrderPaymentStatus",
    },
    {
      title: "Discount",
      dataIndex: "cOrderDiscount",
      key: "cOrderDiscount",
      render: (text) => `${text} %`,
    },
    {
      title: "Order Total",
      dataIndex: "cOrderTotal",
      key: "cOrderTotal",
      render: (text) => `$ ${text}`,
    },
    {
      title: "Payments",
      dataIndex: "cPartyOrderPaymentDetails",
      key: "cPartyOrderPaymentDetails",
      render: (paymentDetails) => {
        // Combine payments by mode
        const combinedPayments = paymentDetails.reduce((acc, payment) => {
          const paymentMode = payment.paymentMode;
          const amountPaid = payment.amountPaid;
          if (acc.hasOwnProperty(paymentMode)) {
            acc[paymentMode] += amountPaid;
          } else {
            acc[paymentMode] = amountPaid;
          }
          return acc;
        }, {});

        // Render combined payments
        return (
          <ul>
            {Object.entries(combinedPayments).map(
              ([paymentMode, amountPaid]) => (
                <li key={paymentMode}>
                  {paymentMode}: ${amountPaid}
                </li>
              )
            )}
          </ul>
        );
      },
    },
    {
      title: "Amount Due",
      dataIndex: "cAmountDue",
      key: "cAmountDue",
      render: (text) => {
        const formattedAmount = text && text >= 0 ? text.toFixed(2) : "0.00";
        return (
          <span
            style={{
              backgroundColor: text && text > 0 ? "yellow" : "transparent",
              fontWeight: text && text > 0 ? "bold" : "normal",
              display: "inline-block",
              padding: "5px",
              borderRadius: "8px",
              margin: "5px",
            }}
          >
            {`$ ${formattedAmount}`}
          </span>
        );
      },      
      // render: (text) => {
      //   // const formattedAmount = text >= 0 ? text.toFixed(2) : "0.00";
      //   const formattedAmount = text >= 0 ? text.toFixed(2) : "0.00";
      //   return (
      //     <span
      //       style={{
      //         backgroundColor: text > 0 ? "yellow" : "transparent",
      //         fontWeight: text > 0 ? "bold" : "normal",
      //         display: "inline-block",
      //         padding: "5px",
      //         borderRadius: "8px",
      //         margin: "5px",
      //       }}
      //     >
      //       {`$ ${formattedAmount}`}
      //     </span>
      //   );
      // },
    },
    
    {
      title: "Actions",
      key: "actions",
      render: renderActions,
    },
  ];

  const handleRefresh = () => {
    window.location.reload();
  };

  const generateInvoice = (order) => {
    const invoiceData = {
      invoiceNumber: order.cInvoiceNumber,
      orderDate: order.cOrderDate,
      customerName: order.cName,
      phoneNumber: order.cPhoneNumber,
      partyOrderItems: order.cPartyOrderItems,
      partyDate: order.cPartyDate,
      orderDiscount: order.cOrderDiscount,
      orderTotal: order.cOrderTotal,
    };

    const invoiceTemplate = `
    <!DOCTYPE html>
    <html>
    <head>
        <title>Restaurant Invoice</title>
        <style>
            body {
                font-family: Arial, sans-serif;
                margin: 0;
                padding: 20px;
                background-color: #f4f4f4;
            }
            
            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 30px;
            }
            
            .header img {
                max-width: 150px;
            }
            
            .header h1 {
                margin: 0;
                font-size: 28px;
            }
            
            .header p {
                margin: 0;
                font-size: 16px;
                color: #555;
            }

            .bill-to-txt {
              color: #708090;
            }
            
            .customer-info {
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;
                margin-top: -20px;
            }
            
            .customer-info h4 {
                margin: 0;
                font-size: 16px;
                color: #555;
            }
            
            .customer-info .left-info {
                text-align: left;
            }
            
            .customer-info .right-info {
                text-align: right;
            }
            
            .table-container {
                margin-bottom: 30px;
            }
            
            table {
                width: 100%;
                border-collapse: collapse;
            }
            
            table th {
                background-color: #708090;
                color: white;
                text-align: center;
            }
            
            table td {
                padding: 12px;
                border: 1px solid #ddd;
                text-align: center;
            }
            
            table td:last-child {
                text-align: left;
            }
            
            .discount {
                margin-bottom: 10px;
                font-size: 16px;
                color: #555;
            }
            
            .order-total {
                margin-bottom: 10px;
                font-size: 18px;
                color: #555;
            }
            
            .signature-field {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;
            }
            
            .signature-field img {
                max-width: 200px;
            }
            
            .qr-code {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-bottom: 20px;
            }
            
            .qr-code img {
                max-width: 150px;
            }
            
            .thanks-message {
                text-align: center;
                margin-bottom: 20px;
                font-size: 16px;
                color: #555;
            }
            
            .footer {
                background-color: #333;
                color: #fff;
                padding: 20px;
                text-align: center;
                font-size: 14px;
            }
            
            .footer p {
                margin: 5px 0;
            }
        </style>
    </head>
    <body>
        <div class="header">
            <img src=${dcLogo} alt="Logo">
            <div>
                <h5>274 Daniel Webster Hwy,<br/><br/>Nashua, NH 03060, US<br/><br/>(+1) 603-722-0770</h5>
            </div>
        </div>
        
        <div class="invoice-info">
            <p><strong>Invoice No:</strong> #${invoiceData.invoiceNumber}</p>
        </div>

        <p class="bill-to-txt"><strong>Bill To:</strong></p>
        
        <div class="customer-info">
            <div class="left-info">
                <p><strong>${invoiceData.customerName}</strong></p>
                <p><strong>(+1) ${invoiceData.phoneNumber}</strong></p>
            </div>
            <div class="right-info">
                <p><strong>Order Date:</strong> ${invoiceData.orderDate}</p>
                <p><strong>Party Date:</strong> ${invoiceData.partyDate}</p>
            </div>
        </div>
        
        <div class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>Item Name</th>
                        <th>Quantity</th>
                        <th>Tray Type</th>
                        <th>Spice Level</th>
                        <th>Item Comments</th>
                        <th>Price</th>
                    </tr>
                </thead>
                <tbody>
                    ${invoiceData.partyOrderItems
                        .map(
                            (item) => `
                                <tr>
                                    <td>${item.itemName}</td>
                                    <td>${item.qty}</td>
                                    <td>${item.trayType}</td>
                                    <td>${item.spiceLevel}</td>
                                    <td>${item.itemComments ? item.itemComments : '-'}</td>
                                    <td style="text-align: left;">$ ${item.price}</td>
                                </tr>
                            `
                        )
                        .join("")}
                    <tr>
                        <td colspan="5" style="text-align: left;"><strong>Total</strong></td>
                        <td style="text-align: left;">$ ${invoiceData.partyOrderItems.reduce((total, item) => total + item.price, 0)}</td>
                    </tr>
                    ${
                        invoiceData.orderDiscount > 0
                            ? `
                    <tr>
                        <td colspan="5" style="text-align: left;"><strong>Discount</strong></td>
                        <td style="text-align: left;">$ -${(invoiceData.orderDiscount / 100) * invoiceData.partyOrderItems.reduce((total, item) => total + item.price, 0)}</td>
                    </tr>
                    <tr>
                        <td colspan="5" style="text-align: left;"><strong>Grand Total</strong></td>
                        <td style="text-align: left;">$ ${invoiceData.partyOrderItems.reduce((total, item) => total + item.price, 0) - (invoiceData.orderDiscount / 100) * invoiceData.partyOrderItems.reduce((total, item) => total + item.price, 0)}</td>
                    </tr>
                    `
                            : ""
                    }
                </tbody>
            </table>
        </div>
        
        <div class="signature-field">
            <div>
                <p>Authorized Signature:</p>
                <img src=${eSignature} alt="Authorized Signature" style="width: 250px">
            </div>
            <div class="qr-code">
                <img src=${reviewQR} alt="QR Code">
            </div>
        </div>
        
        <div class="thanks-message">
            <p>Thank you for choosing our restaurant for your dining experience! We appreciate your order and hope you enjoyed our delicious food. We look forward to serving you again soon.</p>
        </div>
        
        <div class="footer">
            <p>For any inquiries, please contact us at chowrastha.nh@mypi.llc</p>
        </div>
    </body>
    </html>
`;




    const element = document.createElement("div");
    element.innerHTML = invoiceTemplate;

    const options = {
      margin: 10,
      filename: `${invoiceData.customerName}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf().from(element).set(options).save();
  };

  return (
    <>
      <div style={{ marginBottom: "16px", float: "right" }}>
        <Button onClick={handleTodayOrders} style={{ marginRight: "8px" }}>
          Today's Party Orders
        </Button>
        <RangePicker
          onChange={handleDateRangeChange}
          style={{ marginRight: "8px" }}
        />
        <Button onClick={handleFilterSubmit} style={{ marginRight: "8px" }}>
          Apply Filter
        </Button>
        <Button onClick={generateCSV} style={{ marginRight: "8px" }}>
          Download CSV
        </Button>
        <Button icon={<SyncOutlined />} onClick={handleRefresh}></Button>
      </div>
      <h4>Party Orders</h4>
      <Table
        dataSource={filteredOrders.length > 0 ? filteredOrders : orders}
        columns={columns}
        loading={isLoading}
        rowKey="_id"
        pagination={true}
      />
      <Modal
        visible={modalVisible}
        title="Item Details"
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <Table
          dataSource={modalData}
          columns={[
            {
              title: "Item Name",
              dataIndex: "itemName",
              key: "itemName",
            },
            {
              title: "Quantity",
              dataIndex: "qty",
              key: "qty",
            },
            {
              title: "Price",
              dataIndex: "price",
              key: "price",
            },
            {
              title: "Tray Type",
              dataIndex: "trayType",
              key: "trayType",
            },
            {
              title: "Spice Level",
              dataIndex: "spiceLevel",
              key: "spiceLevel",
            },
            {
              title: "Item Comments",
              dataIndex: "itemComments",
              key: "itemComments",
            },
          ]}
          rowKey={(record, index) => index}
        />
      </Modal>
    </>
  );
};

export default OrderTable;
