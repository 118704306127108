import { useState, useEffect } from "react";
import { fetchToastMenuData, fetchFillerData, fetchMenuData, fetchCompletedOrders, fetchOrderNotifications } from "../../api";

const useFetchData = () => {
  const [menu, setMenu] = useState([]);
  const [fillerData, setFillerData] = useState(null);
  const [menuData, setMenuData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const menuData = await fetchToastMenuData();
      const filler = await fetchFillerData();
      const fetchedMenuData = await fetchMenuData();
      setMenu(menuData);
      setFillerData(filler);
      setMenuData(fetchedMenuData);
      setIsLoading(false);
    };

    fetchData();

    const timer = setInterval(() => {
      fetchData();
    }, 300000);

    return () => clearTimeout(timer);
  }, []);

  return { menu, fillerData, menuData, isLoading };
};

export default useFetchData;