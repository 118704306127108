import React from 'react'
import { Button, Row, Col, Typography, Card } from 'antd'
import { GoogleOutlined } from '@ant-design/icons'
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth'
import { auth, provider } from '../firebase'
import userService from './services/userService'
import { useNavigate } from 'react-router-dom'
import './index.css'

const { Title } = Typography

const RatingAndReviewApplication = () => {
  const navigate = useNavigate()

  const signIn = async () => {
    try {
      const auth = getAuth()
      const result = await signInWithPopup(auth, new GoogleAuthProvider())
      const user = result.user
      if (user) {
        const userToPass = {
          googleUID: user.uid,
          name: user.displayName,
          email: user.email,
          phone: user.phoneNumber,
          photoURL: user.photoURL,
        }
        navigate('/nashua/submit-rating', { state: { user: userToPass } })
      }
    } catch (error) {
      console.error(error.message)
    }
  }

  return (
    <div className='login'>
      <Row justify='center' align='middle' className='full-height'>
        <Col xs={24} sm={24} md={24} lg={24}>
          <div className='login-content'>
            <Card bordered={false}>
              <img
                src='https://i.ibb.co/8xznq6N/dc-nashua-logo.webp'
                alt='Restaurant Logo'
                className='logo'
              />
              <Title level={3} className='leave-review-msg'>
                Leave us a review. It will help us grow and better serve our
                customers like you.
              </Title>
              <Button
                type='primary'
                icon={<GoogleOutlined />}
                size='large'
                className='signin-button'
                onClick={signIn}
              >
                <b>Continue with Google</b>
              </Button>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default RatingAndReviewApplication
