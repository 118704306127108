import React, { useState } from "react";
import { Table, Select } from "antd";
import "./index.css";

const { Option } = Select;

const ResponsiveTable = (props) => {
  const [filterCategory, setFilterCategory] = useState("");

  const handleCategoryFilter = (value) => {
    setFilterCategory(value);
  };

  const filteredData = props.dataSource.filter((item) =>
    filterCategory === "" ? true : item.iCategory === filterCategory
  );

  return (
    <>
      <Select
        style={{ marginBottom: "24px", width: "100%" }}
        value={filterCategory}
        onChange={handleCategoryFilter}
        placeholder="Filter by category"
      >
        <Option value="">All</Option>
        <Option value="Condiment Supply">Condiment Supply</Option>
        <Option value="Front Desk">Front Desk</Option>
        <Option value="Kitchen Area">Kitchen Area</Option>
        <Option value="Common">Common</Option>
        <Option value="Chaat">Chaat</Option>
      </Select>
      <Table {...props} dataSource={filteredData} className="hidden-xs" />
      <div className="visible-xs">
        {filteredData.map((row) => (
          <div key={row._id} className="responsive-row">
            {props.columns.map((column) => (
              <div key={column.key} className="responsive-cell">
                <div className="cell-title">{column.title}</div>
                <div className="cell-data">
                  {column.render ? column.render(row[column.dataIndex], row) : row[column.dataIndex]}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default ResponsiveTable;