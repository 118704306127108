/* Importing TV Menu CSS */
import "./tvmenu-styles.css";

import VEG from "../../assets/img/veg.png";
import NONVEG from "../../assets/img/non-veg.png";

export const renderToastMenuItems = (menu, category) => {
  const reducedSpacingCategories = ["Tiffins", "Breads", "Sides", "Veg  Curries", "Non-Veg  Curries", "Pulao", "Veg  Appetizers", "Non-Veg  Appetizers"];

  return menu
    .filter((item) => item.category === category)
    .map((item) => {
      const itemName =
        category === "Non-Veg  Curries" && item.name === "Chowrastha Special Chicken"
          ? "Chowrastha Spl. Chicken"
          : item.name;

      return (
        <div
          key={item.id}
          className={`menu-item ${reducedSpacingCategories.includes(category) ? "reduced-spacing" : ""}`}
        >
          <h4
            style={{ fontFamily: "Bree Serif" }}
            className={item.status === "Not Available" ? "sold-out-menu-item-name" : ""}
          >
            {item.type && category !== "Snacks (Available from 5 PM)" && (
              <img
                src={item.type === "Veg" ? VEG : NONVEG}
                alt={item.name}
                style={{ width: 28, marginRight: "10px" }}
              />
            )}
            {itemName}
            <span style={{ float: "right" }}>
              {item.status === "Not Available"
                ? "N/A"
                : `$ ${parseFloat(item.price).toFixed(2)}`}
            </span>
          </h4>
        </div>
      );
    });
};