export const fetchMenuData = async () => {
    try {
        const response = await fetch("https://chowrastha-client-backend.azurewebsites.net/menu");
        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err.message);
        return [];
    }
};

export const fetchToastMenuData = async () => {
    try {
        const response = await fetch("https://chowrastha-client-backend.azurewebsites.net/toast-menu");
        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err.message);
        return [];
    }
};

export const fetchFillerData = async () => {
    try {
        const response = await fetch("https://nashua-partyorders.azurewebsites.net/filler-data", { mode: 'cors' });
        const data = await response.json();
        console.log(data);
        return data;
    } catch (err) {
        console.log(err.message);
        return [];
    }
};