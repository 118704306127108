import React, { useState, useEffect } from 'react';

/* Importing Ant Design */
import { Card, Typography, Divider, Row, Col, Table, Image, Space, Button, Input, Form, Modal, Select, Badge, Tabs } from 'antd';
import { CalendarOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

/* Importing Axios */
import axios from 'axios';

/* Importing Moment */
import moment from 'moment';

/* Importing CSS */
import './index.css';

/* Importing Assets */
import Logo from "../../Assets/logo-new.svg";
import OrdersNotFound from "../../Assets/OrdersNotFound.png";

const { Title, Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const PartyOrdersChefAppNew = () => {
  const [orders, setOrders] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isIngredientModalVisible, setIsIngredientModalVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const [ongoingOrders, setOngoingOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);

  const [todaysOrders, setTodaysOrders] = useState([]);
  const [tomorrowsOrders, setTomorrowsOrders] = useState([]);

  const [orderDateFilter, setOrderDateFilter] = useState(null);
  const [partyDateFilter, setPartyDateFilter] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get('https://desichowrastha-api-d5us.onrender.com/api/partyorders/list');
        const orders = response.data.result;

        const currentDate = moment();

        const ongoingOrders = orders.filter((order) => {
          const partyDate = moment(order.cPartyDate, 'YYYY-MM-DD');
          return partyDate.isSameOrAfter(currentDate);
        });

        const completedOrders = orders.filter((order) => {
          const partyDate = moment(order.cPartyDate, 'YYYY-MM-DD');
          return partyDate.isBefore(currentDate);
        });

        const todaysOrders = orders.filter((order) => {
          const partyDate = moment(order.cPartyDate, 'YYYY-MM-DD');
          return partyDate.isSame(currentDate, 'day');
        });

        const tomorrowsOrders = orders.filter((order) => {
          const partyDate = moment(order.cPartyDate, 'YYYY-MM-DD');
          return partyDate.isSame(currentDate.clone().add(1, 'days'), 'day');
        });

        setTodaysOrders(todaysOrders);
        setTomorrowsOrders(tomorrowsOrders);

        setOrders(orders);
        setOngoingOrders(ongoingOrders);
        setCompletedOrders(completedOrders);
      } catch (error) {
        console.log(error);
      }
    };

    // Fetch orders initially
    fetchOrders();

    // Set an interval to fetch orders every 30 minutes
    const interval = setInterval(() => {
      fetchOrders();
    }, 30 * 60 * 1000);

    // Clear the interval when the component is unmounted
    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleOrderDateFilterChange = (value) => {
    setOrderDateFilter(value);
  };

  const handlePartyDateFilterChange = (value) => {
    setPartyDateFilter(value);
  };

  const filterOrders = (orders) => {
    let filteredOrders = orders;

    if (orderDateFilter) {
      filteredOrders = filteredOrders.filter((order) => order.cOrderDate === orderDateFilter);
    }

    if (partyDateFilter) {
      filteredOrders = filteredOrders.filter((order) => order.cPartyDate === partyDateFilter);
    }

    return filteredOrders;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showIngredientsModal = () => {
    setIsIngredientModalVisible(true);
  };

  const handleIngredientsModalCancel = () => {
    setIsIngredientModalVisible(false);
  };

  const handleAddIngredients = async (values) => {
    console.log('Adding ingredients to invoiceNumber:', values.invoiceNumber);
    console.log('Ingredients:', values.ingredients);

    const order = orders.find((order) => order.cInvoiceNumber === values.invoiceNumber);

    const updatedOrder = {
      ...order,
      ingredients: values.ingredients,
    };

    try {
      const response = await axios.patch(`https://desichowrastha-api-d5us.onrender.com/api/partyorders/update/${order.cInvoiceNumber}`, updatedOrder);
      console.log(response.data);
      setIsModalVisible(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleInvoiceSelect = (invoiceNumber) => {
    const order = orders.find((order) => order.cInvoiceNumber === invoiceNumber);
    setSelectedOrder(order);
  };

  const cardStyle = {
    marginBottom: 20,
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    borderRadius: '10px',
    padding: '20px',
    height: '100%'
  };

  const columns = [
    {
      title: 'Item Name',
      dataIndex: 'itemName',
      key: 'itemName',
    },
    {
      title: 'Quantity',
      dataIndex: 'qty',
      key: 'qty',
    },
    {
      title: 'Tray',
      dataIndex: 'trayType',
      key: 'trayType',
    },
    {
      title: 'Spice Level',
      dataIndex: 'spiceLevel',
      key: 'spiceLevel',
    },
    {
      title: 'Comments',
      dataIndex: 'itemComments',
      key: 'itemComments',
    },
  ];

  const renderCard = (order) => {
    let statusColor;
    let statusText;

    if (order.cPartyOrderStatus === "COMPLETED") {
      statusColor = "blue";
      statusText = "Completed";
    }

    const partyDate = moment(order.cPartyDate, 'YYYY-MM-DD');
    const currentDate = moment();

    const isPastOrder = partyDate.isBefore(currentDate);

    return (
      <Col xs={24} md={24} lg={24} key={order.cInvoiceNumber} style={{ padding: '8px', marginTop: '16px' }}>
        <Badge.Ribbon text={statusText} color={statusColor}>
          <Card
            title={
              <div>
                <span>
                  #{order.cInvoiceNumber}
                </span>
              </div>
            }
            style={cardStyle}
            headStyle={{ backgroundColor: '#f0f2f5', fontWeight: 'bold' }}
          >
            <Title level={4}>Name: {order.cName}  (Ready at {order.cOrderDeliveryTime})</Title>
            <Space>
              <CalendarOutlined />
              <Text>Order Date: {order.cOrderDate}</Text>
            </Space>
            <br />
            <Space>
              <CalendarOutlined />
              <Text>Party Date: {order.cPartyDate}</Text>
            </Space>
            <Divider orientation="left">Items</Divider>
            <Table
              dataSource={order.cPartyOrderItems}
              columns={columns}
              pagination={false}
              rowKey="_id"
              size="small"
              rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
            />
            {order.ingredients.length > 0 ? (
              <>
                <Button type='primary' style={{ float: "right", marginTop: "20px" }} onClick={showIngredientsModal}>View Ingredients</Button>
                <Modal title="Ingredients" visible={isIngredientModalVisible} onCancel={handleIngredientsModalCancel} footer={null}>
                  {order.ingredients.map((ingredient) => (
                    <>
                      <Divider orientation="left">Ingredients</Divider>
                      <Table
                        dataSource={order.ingredients}
                        columns={[
                          {
                            title: 'Ingredient Name',
                            dataIndex: 'ingredientName',
                            key: 'ingredientName',
                          },
                          {
                            title: 'Quantity',
                            dataIndex: 'ingredientQty',
                            key: 'ingredientQty',
                          },
                        ]}
                        pagination={false}
                        rowKey="_id"
                        size="small"
                        rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
                      />
                    </>
                  ))}
                </Modal>

              </>
            ) : (
              <div style={{ textAlign: 'center' }}>
                <br />
                <Text>No ingredients added. Click on the "Add Ingredients" button to add ingredients.</Text>
              </div>
            )}
          </Card>
        </Badge.Ribbon>
      </Col>
    );
  };

  return (
    <div className='partyorders'>
      {orders.length === 0 ? (
        <div className="container">
          <div>
            <Image src={OrdersNotFound} width={500} />
            <Col style={{ textAlign: 'center' }}>
              <Title level={2}>No party orders found.</Title>
            </Col>
          </div>
        </div>
      ) : (
        <>
          <Card className='header'>
            <div>
              <img src={Logo} className="img-left" alt='Logo' />
              <Button
                size="large"
                className='antd-btn'
                onClick={showModal}
                style={{ float: 'right' }}
              >
                <PlusOutlined />
                Add Ingredients
              </Button>
            </div>
          </Card>
          <Modal title="Add Ingredients" visible={isModalVisible} onCancel={handleCancel} footer={null}>
            <Form onFinish={handleAddIngredients}>
              <Form.Item name="invoiceNumber" label="Invoice Number" rules={[{ required: true, message: 'Please select an invoice number!' }]}>
                <Select placeholder="Select an invoice number" onSelect={handleInvoiceSelect}>
                  {ongoingOrders.map((order) => (
                    <Option key={order.cInvoiceNumber} value={order.cInvoiceNumber}>
                      #{order.cInvoiceNumber}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {selectedOrder && (
                <>
                  <Text strong>Customer Name: {selectedOrder.cName}</Text>
                  <Divider orientation="left">Items</Divider>
                  <Table
                    dataSource={selectedOrder.cPartyOrderItems}
                    columns={columns}
                    pagination={false}
                    rowKey="_id"
                    size="small"
                    style={{ marginBottom: "32px" }}
                    rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
                  />
                  <Form.List name="ingredients">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field) => (
                          <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                            <Form.Item
                              {...field}
                              name={[field.name, 'ingredientName']}
                              fieldKey={[field.fieldKey, 'ingredientName']}
                              rules={[{ required: true, message: 'Please enter an ingredient name!' }]}
                            >
                              <Input placeholder="Enter Ingredient Name" />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, 'ingredientQty']}
                              fieldKey={[field.fieldKey, 'ingredientQty']}
                              rules={[{ required: true, message: 'Please enter a quantity!' }]}
                            >
                              <Input placeholder="Enter Quantity" />
                            </Form.Item>
                            <Button type="dashed" onClick={() => remove(field.name)} icon={<MinusCircleOutlined />} />
                          </Space>
                        ))}
                        <br />
                        <Form.Item>
                          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            Add Ingredients
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </>
              )}
              <Form.Item>
                <Button className='antd-btn' htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Modal>
          <Tabs
            defaultActiveKey="today"
            centered
            tabBarUnderlineStyle={{ display: 'none' }}
            tabBarStyle={{ backgroundColor: '#f0f2f5', padding: '10px 0', marginTop: '20px', borderRadius: "10px" }}
            tabBarGutter={10}
            type="card"
            activeTabClassName="active-tab"
            tabBarActiveTextColor="#fff"
          >
            <TabPane
              tab={
                <span>
                  Today's Orders {todaysOrders.length > 0 && <Badge color='green' count={todaysOrders.length} />}
                </span>
              }
              key="today"
            >
              {todaysOrders.length === 0 ? (
                <div className="container">
                  <div>
                    <Image src={OrdersNotFound} width={500} />
                    <Col style={{ textAlign: 'center' }}>
                      <Title level={2}>No orders today.</Title>
                    </Col>
                  </div>
                </div>
              ) : (
                todaysOrders.map(renderCard)
              )}
            </TabPane>

            <TabPane
              tab={
                <span>
                  Tomorrow's Orders {tomorrowsOrders.length > 0 && <Badge color='green' count={tomorrowsOrders.length} />}
                </span>
              }
              key="tomorrow"
            >
              {tomorrowsOrders.length === 0 ? (
                <div className="container">
                  <div>
                    <Image src={OrdersNotFound} width={500} />
                    <Col style={{ textAlign: 'center' }}>
                      <Title level={2}>No orders tomorrow.</Title>
                    </Col>
                  </div>
                </div>
              ) : (
                tomorrowsOrders.map(renderCard)
              )}
            </TabPane>


            <TabPane
              tab={
                <span>
                  Upcoming Orders {ongoingOrders.length > 0 && <Badge color='green' count={ongoingOrders.length} />}
                </span>
              }
              key="ongoing"
            >
              {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
                                <Select
                                    placeholder="Filter by Order Date"
                                    onChange={handleOrderDateFilterChange}
                                    allowClear
                                    style={{ width: '48%' }}
                                >
                                    {Array.from(new Set(ongoingOrders.map((order) => order.orderDate))).map((date) => (
                                        <Option key={date} value={date}>
                                            {date.substr(0, 4)}-{date.substr(4, 2)}-{date.substr(6, 2)}
                                        </Option>
                                    ))}
                                </Select>
                                <Select
                                    placeholder="Filter by Party Date"
                                    onChange={handlePartyDateFilterChange}
                                    allowClear
                                    style={{ width: '48%' }}
                                >
                                    {Array.from(new Set(ongoingOrders.map((order) => order.partyDate))).map((date) => (
                                        <Option key={date} value={date}>
                                            {date.substr(0, 4)}-{date.substr(4, 2)}-{date.substr(6, 2)}
                                        </Option>
                                    ))}
                                </Select>
                            </div> */}
              {filterOrders(ongoingOrders).map(renderCard)}
            </TabPane>
            {/* <TabPane
                            tab={
                                <span>
                                    Completed Orders {completedOrders.length > 0 && <Badge color='green' count={completedOrders.length} />}
                                </span>
                            }
                            key="completed"
                        >
                            {completedOrders.map(renderCard)}
                        </TabPane> */}
          </Tabs>

          {/* <Row gutter={[16, 16]}>{orders.map(renderCard)}</Row> */}
          <style jsx>{`
            .table-row-light {
              background-color: #f0f2f5;
            }
            .table-row-dark {
              background-color: #e6e9ed;
            }
          `}</style>
        </>
      )}
    </div>
  );
};

export default PartyOrdersChefAppNew;
