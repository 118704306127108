import React from 'react';
import { Row, Col, Button, Card, Carousel, Badge } from 'react-bootstrap';

const Home = () => {
  const handleOrderNowClick = () => {
    window.open("https://www.toasttab.com/desi-chowrastha-nashua-274-daniel-webster-highway", "_blank");
  };

  const handleViewMenuClick = () => {
    window.open("https://desichowrastha-nashua-menu.web.app/menu", "_blank");
  };

  return (
    <div>
      <Row style={{ marginTop: 20 }}>
        <Col md={7}>
          <img src="https://www.desichowrastha.com/images/icons/left-graphics.svg" alt="left-graphics" />
          <h2 style={{ marginTop: 20 }}>Nashua, NH</h2>
          <p>
            Now open in Nashua, New Hampshire, this allows easy access to Manchester, Boston, Lowell, and Concord residents.
            Chowrastha serves the best southern Indian food in town. We provide authentic Irani chai, dum briyani, chaat items, and many more.
          </p>
          <p style={{ fontWeight: 600 }}><img src="https://www.desichowrastha.com/images/icons/location_green_icon.svg" />&nbsp;&nbsp; 274 Daniel Webster Hwy, Nashua, NH 03060</p>
          <p style={{ fontWeight: 600 }}><img src="https://www.desichowrastha.com/images/icons/phone_green_icon.svg" />&nbsp;&nbsp; +1 603-722-0770</p>
          <p style={{ fontWeight: 600 }}><img src="https://www.desichowrastha.com/images/icons/clock_green_icon.svg" />&nbsp;&nbsp; Mon-Sun 10am - 10pm</p>
          <Row>
            <Col>
              <Button onClick={handleOrderNowClick} style={{ marginTop: 10, marginRight: 30 }} variant="outline-success">Order Now</Button>
              <Button onClick={handleViewMenuClick} style={{ marginTop: 10 }} variant="success">View Menu</Button>
            </Col>
          </Row>
        </Col>
        <Col md={5}>
          <h2 style={{ marginLeft: 10, fontFamily: "Lobster", color: "#fd590d" }}>Our Signature Dishes</h2>
          <Card style={{ border: "none" }}>
            <Card.Body>
              <Carousel>
                <Carousel.Item>
                  <img
                    className="d-block w-100 rounded fluid"
                    src="https://i.postimg.cc/TwhJnDVn/Chicken-Dum-Biryani.jpg"
                    alt="Chicken Dum Biryani"
                  />
                  <Badge bg="light" text='dark' variant="success" style={{ position: 'absolute', top: '16px', right: '16px' }}>
                    Chicken Dum Biryani
                  </Badge>
                  {/* <Carousel.Caption>
                    <h3>Chicken Dum Biryani</h3>
                    <p>Fragrant basmati rice cooked with succulent chicken pieces and aromatic spices, a classic Indian delicacy.</p>
                  </Carousel.Caption> */}
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100 rounded fluid"
                    src="https://i.imghut.com/2023/02/02/Samosa-2-PCS.jpg"
                    alt="Samosa (2 PCS)"
                  />
                  <Badge bg="light" text='dark' variant="success" style={{ position: 'absolute', top: '16px', right: '16px' }}>
                    Samosa (2 PCS)
                  </Badge>
                  {/* <Carousel.Caption>
                    <h3>Samosa (2 PCS)</h3>
                    <p>Crispy pastry filled with a savory mixture of spiced potatoes and peas, served with tangy chutney.</p>
                  </Carousel.Caption> */}
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100 rounded fluid"
                    src="https://i.imghut.com/2023/02/02/Chowrastha-Fried-Chicken.jpg"
                    alt="Chowrastha Fried Chicken (CFC)"
                  />
                  <Badge bg="light" text='dark' variant="success" style={{ position: 'absolute', top: '16px', right: '16px' }}>
                  Chowrastha Fried Chicken (CFC)
                  </Badge>
                  {/* <Carousel.Caption>
                    <h3>Chowrastha Fried Chicken (CFC)</h3>
                    <p>
                      Crispy and flavorful fried chicken made with a special blend of spices, a signature dish of the restaurant.
                    </p>
                  </Carousel.Caption> */}
                </Carousel.Item>
              </Carousel>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Home;