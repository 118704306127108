import React, { useState, useEffect } from "react";
import { Table, message, Button, Popconfirm, Space, Card, Modal, Form, Input, ConfigProvider, Col } from "antd";
import { PlusOutlined, MinusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import { useMediaQuery } from 'react-responsive';

import ResponsiveTable from "./ResponsiveTableComponent/index";

const InventoryList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const handleAddQuantity = async (record) => {
    const newQuantity = parseInt(record.iQuantity, 10) + 1;

    try {
      await axios.patch(`https://chowrastha-admin-panel.azurewebsites.net/api/inventory/update/${record._id}`, { iQuantity: newQuantity.toString() });

      // Update the state
      setData(data.map((item) => (item._id === record._id ? { ...item, iQuantity: newQuantity.toString() } : item)));
    } catch (error) {
      message.error("Error updating item quantity");
    }
  };

  const handleRemoveQuantity = async (record) => {
    let newQuantity = parseInt(record.iQuantity, 10);

    if (newQuantity > 0) {
      newQuantity -= 1;

      try {
        await axios.patch(`https://chowrastha-admin-panel.azurewebsites.net/api/inventory/update/${record._id}`, { iQuantity: newQuantity.toString() });

        // Update the state
        setData(data.map((item) => (item._id === record._id ? { ...item, iQuantity: newQuantity.toString() } : item)));
      } catch (error) {
        message.error("Error updating item quantity");
      }
    } else {
      message.warning("Reached Limit. Make an Order");
    }
  };

  const handleEdit = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  const EditModal = ({ visible, onOk, onCancel, record }) => {
    const [form] = Form.useForm();

    useEffect(() => {
      if (record) {
        form.setFieldsValue(record);
      }
    }, [record, form]);

    const handleFormSubmit = async () => {
      try {
        const values = await form.validateFields();
        onOk(values);
      } catch (error) {
        console.log("Error submitting the form:", error);
      }
    };

    return (
      <Modal
        title="Edit Inventory Item"
        visible={visible}
        onOk={handleFormSubmit}
        onCancel={onCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="iName"
            label="Name"
            rules={[{ required: true, message: "Please input the name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="iCategory"
            label="Category"
            rules={[{ required: true, message: "Please input the category!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="iQuantity"
            label="Quantity"
            rules={[{ required: true, message: "Please input the quantity!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="iUnit"
            label="Unit"
            rules={[{ required: true, message: "Please input the unit!" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const handleModalOk = async (values) => {
    try {
      await axios.patch(`https://chowrastha-admin-panel.azurewebsites.net/api/inventory/update/${selectedRecord._id}`, values);

      // Update the state
      setData(
        data.map((item) =>
          item._id === selectedRecord._id ? { ...item, ...values } : item
        )
      );
      message.success("Item updated successfully");
    } catch (error) {
      message.error("Error updating item");
    }

    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleDelete = async (record) => {
    try {
      await axios.delete(`https://chowrastha-admin-panel.azurewebsites.net/api/inventory/delete/${record._id}`);

      // Update the state
      setData(data.filter((item) => item._id !== record._id));
      message.success("Item deleted successfully");
    } catch (error) {
      message.error("Error deleting item");
    }
  };

  const handlePlaceOrder = (record) => {
    // Implement your place order functionality here
    console.log("Placing order for:", record);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "iName",
      key: "iName",
    },
    {
      title: "Category",
      dataIndex: "iCategory",
      key: "iCategory",
      align: "center",
      sorter: (a, b) => a.iCategory.localeCompare(b.iCategory),
    },
    {
      title: "Quantity",
      dataIndex: "iQuantity",
      key: "iQuantity",
      align: "center",
      render: (text, record) => `${text} ${record.iUnit.toUpperCase()}`
    },
    {
      title: "Add / Remove Item Qty",
      key: "addorremoveitmqty",
      render: (_, record) => (
        <Space size="middle">
          <Button type="dashed" icon={<MinusOutlined />} onClick={() => handleRemoveQuantity(record)} />
          <Button type="dashed" icon={<PlusOutlined />} onClick={() => handleAddQuantity(record)} />
        </Space>
      ),
      align: "center",
    },
    // {
    //   title: "Actions",
    //   key: "actions",
    //   render: (_, record) => (
    //     <Space size="middle">
    //       <Button
    //         type="dashed"
    //         icon={<EditOutlined />}
    //         onClick={() => handleEdit(record)}
    //         disabled
    //       />
    //       <Popconfirm
    //         title="Are you sure you want to delete this item?"
    //         onConfirm={() => handleDelete(record)}
    //         okText="Yes"
    //         cancelText="No"
    //       >
    //         <Button type="primary" danger disabled icon={<DeleteOutlined />} />
    //       </Popconfirm>
    //     </Space>
    //   ),
    //   align: "center",
    // },
    // {
    //   title: "Place Order",
    //   key: "placeOrder",
    //   render: (_, record) => (
    //     <Button type="default" onClick={() => handlePlaceOrder(record)} disabled>
    //       Place Order
    //     </Button>
    //   ),
    //   align: "center",
    // },
  ];

  useEffect(() => {
    setLoading(true);
    axios
      .get("https://chowrastha-admin-panel.azurewebsites.net/api/inventory/list")
      .then((res) => {
        setData(res.data.result);
        setLoading(false);
      })
      .catch((err) => {
        message.error("Error fetching inventory data");
        setLoading(false);
      });
  }, []);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <div style={{ background: "#fff" }}>
      <ResponsiveTable
        columns={columns}
        dataSource={data}
        rowKey="_id"
        loading={loading}
        pagination={{ pageSize: 60 }}
        size="middle"
        title={() => <h2>Inventory Management</h2>}
      />
      <EditModal
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        record={selectedRecord}
      />
      {/* <Card bordered={false} style={{ margin: "-24px", marginTop: "-48px" }}>
        
      </Card> */}
    </div>
  );
};

export default InventoryList;