import React, { useState, useEffect } from 'react';

/* React Helmet */
import { Helmet } from 'react-helmet';

import "./index.css";

/* Importing React Bootstrap */
import Container from "react-bootstrap/Container";
import Table from 'react-bootstrap/Table';

function OrdersPage() {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchOrders = async () => {
            await fetch('https://chowrastha-client-backend.azurewebsites.net/orders/pending')
                .then(response => response.json())
                .then(data => setData(data))
                .catch((err) => {
                    console.log(err.message);
                });
        };

        fetchOrders();

        const timer = setInterval(() => {
            fetchOrders();
        }, 100000);

        return () => clearTimeout(timer);
    }, []);

    if(data != null) {
    return (
        <>
            <Helmet>
                <title>DesiChowrastha Nashus | Orders</title>
            </Helmet>
            <br />
            <Table striped responsive>
                <thead>
                    <tr>
                        <th>
                            <h2>Item Name</h2>
                        </th>
                        <th>
                            <h2 style={{ textAlign: "center" }}>Quantity</h2>
                        </th>
                        <th>
                            <h2 style={{ textAlign: "center" }}>Check Number</h2>
                        </th>
                        <th>
                            <h2 style={{ textAlign: "center" }}>Time Taken</h2>
                        </th>
                    </tr>
                </thead>
                {data.map((item) => {
                    if (item.name !== "Paneer Tikka Kebab" && item.name !== "Chicken Tandoor (4 PCS)" &&
                        item.name !== "Chicken Tikka (7 PCS)" && item.name !== "Malali Tikka (7 PCS)" &&
                        item.name !== "Butter Naan" && item.name !== "Plain Naan" && item.name !== "Garlic Naan") {
                        return (
                            <>
                                <tbody>
                                    <tr>
                                        <td>
                                            <h2>{item.name}</h2>
                                        </td>
                                        <td>
                                            <h2 style={{ textAlign: "center" }}>{item.quantity}</h2>
                                        </td>
                                        <td>
                                            {item.details.map((itemDetails, index) => {
                                                if (itemDetails.pendingOrderTime <= 20) {
                                                    return (
                                                        <>
                                                            <h2 style={{ backgroundColor: "green", color: "white", height: "46px", textAlign: "center" }}>
                                                                # {itemDetails.pendingOrderID} - ( {itemDetails.pendingOrderItemQty} Qty )
                                                            </h2>
                                                        </>
                                                    );
                                                } else if (itemDetails.pendingOrderTime >= 21 && itemDetails.pendingOrderTime <= 30) {
                                                    return (
                                                        <>
                                                            <h2 style={{ backgroundColor: "yellow", color: "black", height: "46px", textAlign: "center" }}>
                                                                # {itemDetails.pendingOrderID} - ( {itemDetails.pendingOrderItemQty} Qty )
                                                            </h2>
                                                        </>
                                                    );
                                                } else if (itemDetails.pendingOrderTime >= 31) {
                                                    return (
                                                        <>
                                                            <h2 style={{ backgroundColor: "red", color: "white", height: "46px", textAlign: "center" }}>
                                                                # {itemDetails.pendingOrderID} - ( {itemDetails.pendingOrderItemQty} Qty )
                                                            </h2>
                                                        </>
                                                    );
                                                }
                                            })}
                                        </td>
                                        <td>
                                            {item.details.map((itemDetails, index) => {
                                                if (itemDetails.pendingOrderTime <= 20) {
                                                    return (
                                                        <>
                                                            <h2 style={{ backgroundColor: "green", color: "white", height: "46px", textAlign: "center" }}>
                                                                {itemDetails.pendingOrderTime} Minutes
                                                            </h2>
                                                        </>
                                                    );
                                                } else if (itemDetails.pendingOrderTime >= 21 && itemDetails.pendingOrderTime <= 30) {
                                                    return (
                                                        <>
                                                            <h2 style={{ backgroundColor: "yellow", color: "black", height: "46px", textAlign: "center" }}>
                                                                {itemDetails.pendingOrderTime} Minutes
                                                            </h2>
                                                        </>
                                                    );
                                                } else if (itemDetails.pendingOrderTime >= 31) {
                                                    return (
                                                        <>
                                                            <h2 style={{ backgroundColor: "red", color: "white", height: "46px", textAlign: "center" }}>
                                                                {itemDetails.pendingOrderTime} Minutes
                                                            </h2>
                                                        </>
                                                    );
                                                }
                                            })}
                                        </td>
                                    </tr>
                                </tbody>
                            </>
                        );
                    }
                })}
            </Table>
        </>
    );
    }
}

export default OrdersPage;
