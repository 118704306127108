import React from 'react';

import InventoryList from "./InventoryList";

const InventoryDashboard = () => {
    return (
      <div>
        <InventoryList />
      </div>
    );
  };
  

export default InventoryDashboard;
