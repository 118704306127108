import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import RatingAndReviewApplication from "./Rating and Review Application";
import RatingComponent from "./Rating and Review Application/components/RatingComponent";
import Dashboard from "./PartyOrders Application/components/Dashboard";
import TvMenu from "./Menu Application/pages/tv-menu/index";
import Page1 from "./Menu Application/pages/tv-menu/menu1/1.js";
import Page2 from "./Menu Application/pages/tv-menu/menu2/2.js";
import Page3 from "./Menu Application/pages/tv-menu/menu3/3.js";
import Page4 from "./Menu Application/pages/tv-menu/menu4/4.js";
import InventoryManagementApp from "./Inventory Management Application/App";
import InventoryDashboard from "./Inventory Management Application/components/Dashboard";
import InventoryList from "./Inventory Management Application/components/InventoryList";
import InventoryForm from "./Inventory Management Application/components/InventoryForm";
import InventoryDetails from "./Inventory Management Application/components/InventoryDetails";
import PartyOrdersChefApp from "./Party Orders Chef Application/Pages/PartyOrders";
import PartyOrdersChefAppNew from "./Party Orders Chef Application/Pages/PartyOrders/indexNew";
import NashuaLandingPageApp from "./Landing Page Application/App";

import OrdersPage from './Orders Application/pages/orders';
import OthersPage from './Orders Application/pages/others';
import CompletedPartyOrders from "./CompletedPartyOrders";

function App() {
  return (
    <Router>
      <Routes>
        {/* DesiChowrastha Nashua Routes */}
        <Route path="nashua" element={<NashuaLandingPageApp />} />

        {/** Menu Application **/}
        {/* <Route path="nashua/tv-menu" element={<TvMenu />} /> */}
        <Route path="nashua/tv-menu/menu1" element={<Page1 />} />
        <Route path="nashua/tv-menu/menu2" element={<Page2 />} />
        <Route path="nashua/tv-menu/menu3" element={<Page3 />} />
        <Route path="nashua/tv-menu/menu4" element={<Page4 />} />

        {/** Inventory Management Application **/}
        <Route
          path="nashua/inventory-management"
          element={<InventoryManagementApp />}
        />
        <Route path="nashua/inventory-management/dashboard" element={<InventoryDashboard />} />
        <Route path="nashua/inventory-management/inventory" element={<InventoryList />} />
        <Route path="nashua/inventory-management/create" element={<InventoryForm />} />
        <Route path="nashua/inventory-management/details/:id" element={<InventoryDetails />} />

        {/** Orders Management Application **/}
        <Route path="nashua/orders" element={<OrdersPage />} />
        <Route path="nashua/orders/others" element={<OthersPage />} />

        {/** PartyOrders Admin Application **/}
        <Route path="/nashua/partyorders/admin" element={<Dashboard />} />

        {/** PartyOrders Chef Application **/}
        <Route path="/nashua/partyorders/chef" element={<PartyOrdersChefApp />} />
        <Route path="/nashua/partyorders/chef-new" element={<PartyOrdersChefAppNew />} />

        {/** PartyOrders - Completed Reference Application **/}
        {/* <Route path="/nashua/partyorders/completed" element={<CompletedPartyOrders />} /> */}

        {/** Rating & Review Application **/}
        <Route path="/nashua/review" element={<RatingAndReviewApplication />} />
        <Route path="/nashua/submit-rating" element={<RatingComponent />} />
        <Route path="/nashua/tv-menu" element={<TvMenu/>} />
        <Route path="nashua/tv-menu/menu1" element={<Page1/>} />
        <Route path="nashua/tv-menu/menu2" element={<Page2/>} />
        <Route path="nashua/tv-menu/menu3" element={<Page3/>} />
        <Route path="nashua/tv-menu/menu4" element={<Page4/>} />
      </Routes>
    </Router>
  );
}

export default App;
