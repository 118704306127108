import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Modal, Button, Row, Col, Input, Form, Select, Rate } from 'antd'
import Avatar from 'react-avatar'
import userService from '../services/userService'

const { TextArea } = Input
const { Option } = Select

const RatingComponent = () => {
  const [selectedRating, setSelectedRating] = useState(0)
  const [foodRating, setFoodRating] = useState(0)
  const [serviceRating, setServiceRating] = useState(0)
  const [atmosphereRating, setAtmosphereRating] = useState(0)
  const [feedback, setFeedback] = useState('')
  const [phone, setPhone] = useState('')
  const [countryCode, setCountryCode] = useState('+1')
  const [showModal, setShowModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()

  const { user } = location.state || {}

  const handleRatingSelection = rating => {
    setSelectedRating(rating)

    if (rating <= 3) {
      setShowModal(true)
    } else {
      window.location.href = 'https://www.google.com/search?hl=en-IN&gl=in&q=Chowrastha+-+Indian+Eatery,+274+Daniel+Webster+Hwy,+Nashua,+NH+03060,+United+States&ludocid=12313414832963681854&lsig=AB86z5UORAfXLSaXmTZ73vMWkQnr#lrd=0x89e3bbc7151c0845:0xaae2098d22d37e3e,3'
    }
  }

  // const handleRatingSelection = rating => {
  //   setSelectedRating(rating);
  
  //   if (rating <= 3) {
  //     setShowModal(true);
  //   } else {
  //     window.open('https://www.google.com/search?hl=en-IN&gl=in&q=Chowrastha+-+Indian+Eatery,+274+Daniel+Webster+Hwy,+Nashua,+NH+03060,+United+States&ludocid=12313414832963681854&lsig=AB86z5UORAfXLSaXmTZ73vMWkQnr#lrd=0x89e3bbc7151c0845:0xaae2098d22d37e3e,3', '_blank');
  //   }
  // }  

  const handleModalClose = () => {
    setShowModal(false)
  }

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false)
    navigate('/nashua')
  }

  const handleRatingChange = (ratingType, rating) => {
    if (ratingType === 'food') {
      setFoodRating(rating)
    } else if (ratingType === 'service') {
      setServiceRating(rating)
    } else if (ratingType === 'atmosphere') {
      setAtmosphereRating(rating)
    }
  }

  const handleFeedbackSubmit = async () => {
    try {
      const userToSave = {
        ...user,
        phone: `${countryCode}${phone}`,
        feedback: {
          rating: selectedRating,
          foodRating: foodRating,
          serviceRating: serviceRating,
          atmosphereRating: atmosphereRating,
          message: feedback,
          submittedAt: new Date(),
        },
        created: new Date(),
      }

      await userService.createUser(userToSave)

      setShowModal(false)
      setShowSuccessModal(true)
    } catch (error) {
      console.error('Error submitting feedback:', error)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <div>
        {/* <Row justify='end' align='top' style={{ marginBottom: '20px' }}>
          {user && (
            <Col
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                display: 'flex',
                alignItems: 'center',
                marginRight: '10px',
              }}
            >
              <Avatar name={user.name} size='40' round />
              <div style={{ marginLeft: '10px', marginTop: '-6px' }}>
                <h4>{user.name}</h4>
                <p style={{ marginTop: '-24px' }}>{user.email}</p>
              </div>
            </Col>
          )}
        </Row> */}
        <Row style={{ marginBottom: '20px' }}>
          <Col
            span={24}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img
              src='https://i.ibb.co/8xznq6N/dc-nashua-logo.webp'
              alt='Logo'
              style={{ width: '248px', marginBottom: '10px' }}
            />
            <h2
              style={{
                marginBottom: '20px',
                textAlign: 'center',
                marginTop: '-36px',
              }}
            >
              How was your experience with Desi Chowrastha - Nashua NH?
            </h2>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {[1, 2, 3, 4, 5].map(rating => (
                <span
                  key={rating}
                  onClick={() => handleRatingSelection(rating)}
                  style={{
                    fontSize: '48px',
                    margin: '0 5px',
                    color: selectedRating >= rating ? '#FFD700' : '#C0C0C0',
                    cursor: 'pointer',
                  }}
                >
                  &#9733;
                </span>
              ))}
            </div>
          </Col>
        </Row>
        <Modal
          visible={showModal}
          title='Feedback Form'
          onCancel={handleModalClose}
          footer={[
            <Button key='cancel' onClick={handleModalClose}>
              Cancel
            </Button>,
            <Button key='submit' type='primary' onClick={handleFeedbackSubmit}>
              Submit
            </Button>,
          ]}
        >
          <p>
            We want our customers to be 100% satisfied. Please let us know why
            you had a bad experience, so we can improve our service. Leave your
            email to be contacted.
          </p>
          <Form layout='vertical'>
            <Form.Item label='Name'>
              <Input value={user.name} disabled />
            </Form.Item>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label='Email'>
                  <Input value={user.email} disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label='Phone Number'>
                  <Input.Group compact>
                    <Select defaultValue='US' style={{ width: '30%' }}>
                      <Option value='US'>+1</Option>
                      <Option value='UK'>+44</Option>
                      <Option value='China'>+86</Option>
                      <Option value='India'>+91</Option>
                    </Select>
                    <Input
                      style={{ width: '70%' }}
                      placeholder='Phone Number'
                      value={phone}
                      onChange={e => setPhone(e.target.value)}
                    />
                  </Input.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Form.Item label='Food Rating'>
                  <Rate
                    onChange={rating => handleRatingChange('food', rating)}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label='Service Rating'>
                  <Rate
                    onChange={rating => handleRatingChange('service', rating)}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label='Atmosphere Rating'>
                  <Rate
                    onChange={rating =>
                      handleRatingChange('atmosphere', rating)
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label='Feedback'>
              <TextArea
                rows={4}
                placeholder='Please provide your feedback'
                value={feedback}
                onChange={e => setFeedback(e.target.value)}
              />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          visible={showSuccessModal}
          title='Thank you for your feedback!'
          onCancel={handleSuccessModalClose}
          footer={[
            <Button
              key='continue'
              type='primary'
              onClick={handleSuccessModalClose}
            >
              Continue
            </Button>,
          ]}
        >
          <p>
            We appreciate you taking the time to provide us with your valuable
            feedback. Your insights help us continually improve our service.
            Please rest assured, your suggestions will be taken into
            consideration and used to improve our dining experience. Thank you
            for your help.
          </p>
        </Modal>
      </div>
    </div>
  )
}

export default RatingComponent
