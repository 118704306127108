import React from 'react';
import { Row, Col, ListGroup } from 'react-bootstrap';
import { BsGeoAlt } from 'react-icons/bs';

const Footer = () => {
    const locations = [
        {
            name: 'Coppell, TX (Drive-Thru)',
            address: '121 TX-121, Coppell, TX - 75019',
        },
        {
            name: 'The Colony, TX (Castle Hills)',
            address: '4600 TX-121, Lewisville, TX 75056',
        },
        {
            name: 'Herndon, VA',
            address: '905 Herndon Pkwy, Herndon VA 20170',
        },
        {
            name: 'Cedar Park (Austin)',
            address: '14420 Ronald Reagan Boulevard, TX',
        },
        {
            name: 'Nashua, NH',
            address: '274 Daniel Webster Hwy, Nashua, NH 03060',
        },
        {
            name: 'Prosper, TX',
            address: '27379 E University Dr Suite #700, TX 76227',
        },
    ];

    return (
        <div style={{ margin: 24, marginTop: 50 }}>
            <Row>
                <Col md={4}>
                    <img src="https://www.desichowrastha.com/images/logo.svg" alt="Desi Chowrastha Logo" />
                    <p style={{ marginLeft: 6, marginTop: 8 }}>Desi Chowrastha is here, to celebrate culture, heritage through food, much more than nostalgia and recipes.</p>
                    <p style={{ marginLeft: 6, marginTop: -10 }}><strong>Stay Connected</strong></p>
                    <div className="social-icons" style={{ marginLeft: 6 }}>
                        <a href='https://www.facebook.com/DesiChowrastha' target='_blank' style={{ marginRight: 16 }}><img src="https://iili.io/HkW1xhg.png" width={36} alt="Facebook" /></a>
                        <a href='https://www.instagram.com/desichowrastha/' target='_blank'><img src="https://iili.io/HkW1zQa.png" width={36} alt="Instagram" /></a>
                    </div>
                </Col>
                <Col md={2}>
                    <h5>Quick Links</h5>
                    <ListGroup>
                        <ListGroup.Item action href="https://www.desichowrastha.com/home" target="_blank">
                            Home
                        </ListGroup.Item>
                        <ListGroup.Item action href="https://www.desichowrastha.com/locations" target="_blank">
                            Locations
                        </ListGroup.Item>
                        <ListGroup.Item action href="https://www.desichowrastha.com/sunshine-retreat" target="_blank">
                            Sunshine Retreat
                        </ListGroup.Item>
                        <ListGroup.Item action href="https://www.desichowrastha.com/about" target="_blank">
                            About Us
                        </ListGroup.Item>
                        <ListGroup.Item action href="https://www.desichowrastha.com/franchise" target="_blank">
                            Franchise
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
                <Col md={6}>
                    <h5>Our Locations</h5>
                    <Row>
                        <Col md={6}>
                            <ListGroup>
                                {locations.slice(0, 3).map((location, index) => (
                                    <ListGroup.Item key={index}>
                                        <div className="location-item">
                                            <div>
                                                <h6><img src="https://www.desichowrastha.com/images/icons/location_green_icon.svg" />&nbsp;&nbsp; {location.name}</h6>
                                                <p>{location.address}</p>
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Col>
                        <Col md={6}>
                            <ListGroup>
                                {locations.slice(3, 6).map((location, index) => (
                                    <ListGroup.Item key={index}>
                                        <div className="location-item">
                                            <div>
                                                <h6><img src="https://www.desichowrastha.com/images/icons/location_green_icon.svg" />&nbsp;&nbsp; {location.name}</h6>
                                                <p>{location.address}</p>
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default Footer;