import React, { useState, useEffect } from 'react';
import { Table, DatePicker, Button, message, Row, Col } from 'antd';
import { CSVLink } from 'react-csv';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const { MonthPicker, YearPicker } = DatePicker;

const CompletedPartyOrders = () => {
  const [completedOrders, setCompletedOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  useEffect(() => {
    fetch('https://nashua-partyorders.azurewebsites.net/api/partyorders/list')
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          const completedOrdersData = data.result.filter(order => order.cPartyOrderStatus === 'COMPLETED');
          setCompletedOrders(completedOrdersData);
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (selectedMonth && selectedYear) {
      const filteredData = completedOrders.filter(order => {
        const [year, month] = order.cPartyDate.split('-');
        return Number(year) === selectedYear && Number(month) === selectedMonth;
      });
      setFilteredOrders(filteredData);
    } else {
      setFilteredOrders(completedOrders);
    }
  }, [selectedMonth, selectedYear, completedOrders]);

  const handleMonthChange = (date, dateString) => {
    setSelectedMonth(Number(dateString.split('-')[1]));
  };

  const handleYearChange = (date, dateString) => {
    setSelectedYear(Number(dateString));
  };

  const handleDownloadCSV = () => {
    if (filteredOrders.length > 0) {
      message.success('CSV Downloaded Successfully');
    } else {
      message.warning('No orders to export');
    }
  };

  const cashAmount = filteredOrders.reduce((total, order) => {
    return order.cPartyOrderPaymentDetails.reduce((subtotal, payment) => {
      return payment.paymentMode === 'CASH' ? subtotal + payment.amountPaid : subtotal;
    }, total);
  }, 0);

  const zelleAmount = filteredOrders.reduce((total, order) => {
    return order.cPartyOrderPaymentDetails.reduce((subtotal, payment) => {
      return payment.paymentMode === 'ZELLE' ? subtotal + payment.amountPaid : subtotal;
    }, total);
  }, 0);

  const chartData = [
    { paymentMode: 'Cash', amount: cashAmount },
    { paymentMode: 'Zelle', amount: zelleAmount }
  ];

  const csvHeaders = [
    { label: 'Invoice Number', key: 'cInvoiceNumber' },
    { label: 'Customer Name', key: 'cName' },
    { label: 'Order Date', key: 'cPartyDate' },
    { label: 'Total Amount', key: 'cOrderTotal' },
    { label: 'Order Status', key: 'cPartyOrderStatus' },
    { label: 'Payment Status', key: 'cPartyOrderPaymentStatus' },
    { label: 'Amount Due', key: 'cAmountDue' }
  ];

  const columns = [
    {
      title: 'Invoice Number',
      dataIndex: 'cInvoiceNumber',
      key: 'cInvoiceNumber'
    },
    {
      title: 'Customer Name',
      dataIndex: 'cName',
      key: 'cName'
    },
    {
      title: 'Order Date',
      dataIndex: 'cPartyDate',
      key: 'cPartyDate'
    },
    {
      title: 'Total Amount',
      dataIndex: 'cOrderTotal',
      key: 'cOrderTotal'
    },
    {
      title: 'Order Status',
      dataIndex: 'cPartyOrderStatus',
      key: 'cPartyOrderStatus'
    },
    {
      title: 'Payment Status',
      dataIndex: 'cPartyOrderPaymentStatus',
      key: 'cPartyOrderPaymentStatus'
    },
    {
      title: 'Amount Due',
      dataIndex: 'cAmountDue',
      key: 'cAmountDue'
    }
  ];

  return (
    <div style={{ padding: '20px' }}>
      <h2 style={{ marginBottom: '20px', fontSize: '24px' }}>Completed Party Orders</h2>
      <div style={{ marginBottom: '20px' }}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <MonthPicker onChange={handleMonthChange} placeholder="Select Month" style={{ marginRight: '10px' }} />
            <YearPicker onChange={handleYearChange} placeholder="Select Year" style={{ marginRight: '10px' }} />
            <CSVLink data={filteredOrders} headers={csvHeaders} filename="filtered_orders.csv">
              <Button type="primary" onClick={handleDownloadCSV}>
                Download CSV
              </Button>
            </CSVLink>
          </Col>
        </Row>
      </div>
      <Row gutter={[16, 16]}>
        <Col span={16}>
          <div style={{ backgroundColor: '#fff', padding: '16px', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', marginTop: '20px' }}>
            <Table dataSource={filteredOrders} columns={columns} />
          </div>
        </Col>
        <Col span={8}>
          <div style={{ backgroundColor: '#fff', padding: '16px', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', marginTop: '20px' }}>
            <h3 style={{ marginBottom: '16px', fontSize: '18px' }}>Payment Distribution</h3>
            <BarChart width={400} height={300} data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="paymentMode" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="amount" fill="#007bff" />
            </BarChart>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CompletedPartyOrders;
