import React, { useState, useEffect } from 'react';

const Map = () => {
    const [height, setHeight] = useState(window.innerHeight - 360);

    useEffect(() => {
        const handleResize = () => setHeight(window.innerHeight - 360);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const iframe = `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d909.6938201597119!2d-71.44121009309829!3d42.706567224597926!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e3bbc7151c0845%3A0xaae2098d22d37e3e!2sChowrastha%20-%20Indian%20Eatery!5e0!3m2!1sen!2sin!4v1684219717121!5m2!1sen!2sin" width="100%" height="${height}" style="border:0; border-radius: 10px" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`;

    return (
        <div dangerouslySetInnerHTML={{ __html: iframe ? iframe : "" }} />
    );
};

export default Map;