import React, { useState, useEffect } from "react";
import { Form, Input, Button, message, Radio, Select, Row, Col } from "antd";
import moment from "moment";
import api from "../services/api";

const { TextArea } = Input;
const { Option } = Select;

const OrderModal = ({ order, onClose, onOrderCreated, onOrderUpdated }) => {
  const [form] = Form.useForm();
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [isCalculated, setIsCalculated] = useState(false);

  useEffect(() => {
    if (order) {
      form.setFieldsValue(order);
    }
  }, [order, form]);

  useEffect(() => {
    if (!order) { 
      generateInvoiceNumber();
    }
  }, []);

  const generateInvoiceNumber = () => {
    const randomNumber = Math.floor(1000 + Math.random() * 9000);
    const invoiceNumber = randomNumber.toString();
    form.setFieldsValue({
      cInvoiceNumber: invoiceNumber,
    });
    setInvoiceNumber(invoiceNumber);
  };

  const calculateOrderTotal = (items) => {
    let total = 0;
    if (items) {
      total = items.reduce((sum, item) => {
        // const price = (item.price || 0) * (item.qty || 1);
        const price = (item.price || 0);
        return sum + parseFloat(price);
      }, 0);
    }
    return total;
  };

  const calculateAmountDue = (total, discount, paymentDetails) => {
    let amountPaid = 0;
    if (paymentDetails) {
      amountPaid = paymentDetails.reduce((sum, payment) => {
        const paidAmount = payment.amountPaid || 0;
        return sum + parseFloat(paidAmount);
      }, 0);
    }
  
    // Calculate the discount amount as a percentage of the total
    const discountAmount = total * (parseFloat(discount) / 100);
  
    // Subtract the discount amount and amount paid from the total
    const amountDue = total - (discountAmount + amountPaid);
  
    return amountDue;
  };

  const handleValuesChange = (changedValues, allValues) => {
    if (
      changedValues.cPartyOrderItems ||
      changedValues.cOrderDiscount ||
      changedValues.cPartyOrderPaymentDetails
    ) {
      const total = calculateOrderTotal(allValues.cPartyOrderItems);
      const amountDue = calculateAmountDue(
        total,
        allValues.cOrderDiscount,
        allValues.cPartyOrderPaymentDetails
      );
      form.setFieldsValue({
        cOrderTotal: total,
        cAmountDue: amountDue,
      });
    }
  };

  const handleCalculateTotals = () => {
    // read the current form values
    const values = form.getFieldsValue();
    // calculate the totals based on the current form values
    const total = calculateOrderTotal(values.cPartyOrderItems);
    const amountDue = calculateAmountDue(
      total,
      values.cOrderDiscount,
      values.cPartyOrderPaymentDetails
    );
    // set the calculated totals on the form
    form.setFieldsValue({
      cOrderTotal: total,
      cAmountDue: amountDue,
    });

    setIsCalculated(true);
  };  

  const handleCreate = async (values) => {
    try {
      const formattedValues = formatFormValues(values);

      const response = await api.post("/partyorders/create", formattedValues);
      message.success("Order created successfully");
      onOrderCreated();
      onClose();
      form.resetFields();
    } catch (error) {
      console.error("Error creating order:", error);
      message.error("Failed to create the order. Please try again later.");
    }
  };

  const handleUpdate = async (values) => {
    try {
      const formattedValues = formatFormValues(values);

      const response = await api.patch(
        `/partyorders/update/${order._id}`,
        formattedValues
      );
      message.success("Order updated successfully");
      onOrderUpdated();
      onClose();
      form.resetFields();
    } catch (error) {
      console.error("Error updating order:", error);
      message.error("Failed to update the order. Please try again later.");
    }
  };

  const formatFormValues = (values) => {
    const formattedValues = { ...values };
    return formattedValues;
  };

  const handleSubmit = (values) => {
    if (order) {
      handleUpdate(values);
    } else {
      handleCreate(values);
    }
  };

  const formatDate = (value) => {
    return value ? moment(value).format("YYYY-MM-DD") : "";
  };

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
    <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Invoice Number" name="cInvoiceNumber">
            <Input prefix="#" disabled value={invoiceNumber} />
          </Form.Item>
        </Col>
        {/* <Col span={12}>
          <Button onClick={generateInvoiceNumber}>Generate Invoice</Button>
        </Col> */}
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Customer Name"
            name="cName"
            rules={[{ required: true, message: "Please enter the customer name" }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Order Delivery Time"
            name="cOrderDeliveryTime"
            rules={[{ required: true, message: "Please enter the order delivery time" }]}
          >
            <Input type="time" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Order Date"
            name="cOrderDate"
            rules={[{ required: true, message: "Please select the order date" }]}
          >
            <Input type="date" format={formatDate} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Party Date"
            name="cPartyDate"
            rules={[{ required: true, message: "Please select the party date" }]}
          >
            <Input type="date" format={formatDate} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label="Email" name="cEmail">
        <Input type="email" />
      </Form.Item>

      <Form.Item label="Phone Number" name="cPhoneNumber">
        <Input type="tel" />
      </Form.Item>

      <Form.Item label="Party Order Comments" name="cPartyOrderComments">
        <TextArea />
      </Form.Item>

      <Form.List name="cPartyOrderItems">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <div key={field.key}>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name={[field.name, "itemName"]}
                      label="Item Name"
                      rules={[{ required: true, message: "Please enter the item name" }]}
                    >
                      <Input placeholder="Item Name" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="Qty"
                      name={[field.name, "qty"]}
                      rules={[{ required: true, message: "Please enter the quantity" }]}
                    >
                      <Input type="number" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="Price"
                      name={[field.name, "price"]}
                      rules={[{ required: true, message: "Please enter the price" }]}
                    >
                      <Input type="number" addonBefore="$" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      label="Tray Type"
                      name={[field.name, "trayType"]}
                      rules={[{ required: true, message: "Please select the tray type" }]}
                    >
                      <Select placeholder="Tray Type">
                        <Option value="Shallow Tray">Shallow Tray</Option>
                        <Option value="Small Deep / Half Tray">Small Deep / Half Tray</Option>
                        <Option value="Long Medium Tray">Long Medium Tray</Option>
                        <Option value="Long Deep / Full Tray">Long Deep / Full Tray</Option>
                        <Option value="Single Item">Single Item</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      label="Spice Level"
                      name={[field.name, "spiceLevel"]}
                      rules={[{ required: true, message: "Please select the spice level" }]}
                    >
                      <Radio.Group>
                        <Radio value="Mild">Mild</Radio>
                        <Radio value="Medium">Medium</Radio>
                        <Radio value="Spicy">Spicy</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      label="Item Comments"
                      name={[field.name, "itemComments"]}
                    >
                      <TextArea placeholder="Item Comments" />
                    </Form.Item>
                  </Col>
                </Row>
                {fields.length > 1 && (
                  <Row>
                    <Col span={24} style={{ textAlign: "right" }}>
                      <Button danger onClick={() => remove(field.name)}>
                        Remove
                      </Button>
                    </Col>
                  </Row>
                )}
                <br />
              </div>
            ))}
            <Row>
              <Col span={24} style={{ textAlign: "right" }}>
                <Button type="primary" onClick={() => add()} block>
                  Add Item
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Form.List>

      <br />
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Order Status" name="cPartyOrderStatus">
            <Radio.Group>
              <Radio value="CONFIRMED">CONFIRMED</Radio>
              <Radio value="COMPLETED">COMPLETED</Radio>
              <Radio value="CANCELLED">CANCELLED</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Payment Status" name="cPartyOrderPaymentStatus">
            <Radio.Group>
              <Radio value="COD">COD</Radio>
              <Radio value="PARTIAL">PARTIAL</Radio>
              <Radio value="FULL">FULL</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label="Discount (%)" name="cOrderDiscount">
        <Input suffix="%" onChange={calculateAmountDue} />
      </Form.Item>

      

      <Form.List name="cPartyOrderPaymentDetails">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <div key={field.key}>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Payment Mode"
                      name={[field.name, "paymentMode"]}
                      rules={[
                        { required: true, message: "Please select the payment mode" },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value="CASH">CASH</Radio>
                        <Radio value="ZELLE">ZELLE</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Amount Paid"
                      name={[field.name, "amountPaid"]}
                      rules={[
                        { required: true, message: "Please enter the amount paid" },
                      ]}
                    >
                      <Input addonBefore="$" onChange={calculateAmountDue} />
                    </Form.Item>
                  </Col>
                </Row>
                {fields.length > 1 && (
                  <Row>
                    <Col span={24} style={{ textAlign: "right" }}>
                      <Button type="link" danger onClick={() => remove(field.name)}>
                        Remove
                      </Button>
                    </Col>
                  </Row>
                )}
              </div>
            ))}
            <Row>
              <Col span={24} style={{ textAlign: "right" }}>
                <Button type="primary" onClick={() => add()} block>
                  Add Payment Detail
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Form.List>

      <Form.Item name="cOrderTotal" label="Order Total">
    <Input disabled />
</Form.Item>

      <Form.Item name="cAmountDue" label="Amount Due">
    <Input disabled />
</Form.Item>

<Button 
  type="primary" 
  onClick={handleCalculateTotals}
  style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  }}
>
  Calculate Totals &amp; Amount Due
</Button>


      <br />
      <Form.Item>
      <Button type="primary" htmlType="submit" disabled={!isCalculated}>
  {order ? "Update" : "Create"}
</Button>

        <Button htmlType="button" onClick={onClose} style={{ marginLeft: "8px" }}>
          Cancel
        </Button>
      </Form.Item>
    </Form>
  );
};

export default OrderModal;
