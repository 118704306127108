import axios from 'axios'

const baseUrl = 'https://desichowrastha-api-d5us.onrender.com/api/customer'

const createUser = async user => {
  try {
    const response = await axios.post(`${baseUrl}/create`, user)
    return response.data
  } catch (error) {
    // Handle the error
    console.error('Error creating user:', error)
    throw error
  }
}

const readUser = async id => {
  try {
    const response = await axios.get(`${baseUrl}/read/${id}`)
    return response.data
  } catch (error) {
    // Handle the error
    console.error('Error reading user:', error)
    throw error
  }
}

const updateUser = async (googleUID, user) => {
  try {
    const response = await axios.patch(`${baseUrl}/update/${googleUID}`, user)
    return response.data
  } catch (error) {
    // Handle the error
    console.error('Error updating user:', error)
    throw error
  }
}

const deleteUser = async id => {
  try {
    const response = await axios.delete(`${baseUrl}/delete/${id}`)
    return response.data
  } catch (error) {
    // Handle the error
    console.error('Error deleting user:', error)
    throw error
  }
}

const searchUsers = async query => {
  try {
    const response = await axios.get(`${baseUrl}/search`, { params: { query } })
    return response.data
  } catch (error) {
    // Handle the error
    console.error('Error searching users:', error)
    throw error
  }
}

const listUsers = async () => {
  try {
    const response = await axios.get(`${baseUrl}/list`)
    return response.data
  } catch (error) {
    // Handle the error
    console.error('Error listing users:', error)
    throw error
  }
}

export default {
  createUser,
  readUser,
  updateUser,
  deleteUser,
  searchUsers,
  listUsers,
}
