import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";

import { Layout, Avatar, Button, Card, Form, Input, Space, Dropdown, Menu } from "antd";
import { UserOutlined, LockOutlined, DownCircleOutlined } from "@ant-design/icons";

import axios from "axios";

import Dashboard from "./components/Dashboard";
import InventoryList from "./components/InventoryList";
import InventoryForm from "./components/InventoryForm";
import InventoryDetails from "./components/InventoryDetails";

const { Content } = Layout;

function LoginPage({ onLogin }) {
  const [isLoading, setIsLoading] = useState(false);
  const [emailList, setEmailList] = useState([]);

  useEffect(() => {
    axios.get("https://chowrastha-admin-panel.azurewebsites.net/api/admin/list")
      .then(response => {
        const emails = response.data.result.map(user => user.email);
        setEmailList(emails);
      })
      .catch(error => {
        console.error("Error fetching email list:", error);
      });
  }, []);

  const handleLogin = (values) => {
    setIsLoading(true);
    setTimeout(() => {
      const { email, password } = values;
      if (emailList.includes(email) && password === "12345678") {
        onLogin(email);
      } else {
        alert("Invalid login credentials");
      }
      setIsLoading(false);
    }, 2000);
  };  

  return (
    <div className="login-page">
      <img src="https://iili.io/HeKYJkB.png" alt="Logo" />
      <Card style={{ marginTop: "16px" }}>
        <Form
          onFinish={handleLogin}
          size="large"
          initialValues={{ remember: true }}
          style={{ width: "100%" }}
        >
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Please enter your email" },
              { type: "email", message: "Please enter a valid email" },
            ]}
          >
            <Input prefix={<UserOutlined />} placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please enter your password" }]}
          >
            <Input.Password prefix={<LockOutlined />} placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <div className="btn-container">
              <Button type="dashed" htmlType="submit" loading={isLoading}>
                Login
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}

function InventoryManagementApp() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [loggedInUserEmail, setLoggedInUserEmail] = useState(null);

  const handleLogin = (email) => {
    setIsLoggedIn(true);
    setLoggedInUserEmail(email);
    localStorage.setItem("isLoggedIn", "true");
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUser(null);
    setLoggedInUserEmail(null);
    localStorage.removeItem("isLoggedIn");
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
    if (storedIsLoggedIn === "true") {
      setIsLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn && loggedInUserEmail) {
      axios.get("https://chowrastha-admin-panel.azurewebsites.net/api/admin/list")
        .then(response => {
          const userData = response.data.result.find(user => user.email === loggedInUserEmail);
          setUser(userData);
        })
        .catch(error => {
          console.error("Error fetching user data:", error);
        });
    }
  }, [isLoggedIn, loggedInUserEmail]);

  return (
    <>
      {isLoggedIn ? (
        <>
          <Card bordered={false} style={{margin: "24px"}}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div>
                <img className="dc-header-logo" src="https://iili.io/HeKYJkB.png" alt='Logo' />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
              {user && (
                <Dropdown overlay={menu} trigger={["click"]}>
                <Space size="large" style={{ cursor: "pointer" }}>
                  <Avatar shape="square" style={{ backgroundColor: "#f56a00" }}>
                    {user.name && user.name.slice(0, 2).toUpperCase()}
                  </Avatar>
                  <div>
                    <div><strong>{user.name + " " + user.surname}</strong></div>
                    <div>{user.email}</div>
                  </div>
                  <DownCircleOutlined />
                </Space>
              </Dropdown>              
              )}
            </div>
            </div>
          </Card>

            <Layout>
              <Content style={{ padding: "50px" }}>
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/inventory" element={<InventoryList />} />
                  <Route path="/create" element={<InventoryForm />} />
                  <Route path="/details/:id" element={<InventoryDetails />} />
                </Routes>
              </Content>
            </Layout>
        </>
      ) : (
        <LoginPage onLogin={handleLogin} />
      )}
    </>
  );
}

export default InventoryManagementApp;
