import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Map from '../../components/Map';
import ContactForm from '../../components/ContactForm';
// import Footer from './Footer';

const Contact = () => {
  return (
    <div>
      <Row>
        <Col md={6} className="text-center">
          <img src='https://iili.io/HUSoPS9.png' width={360} />
        </Col>
        <Col md={6}>
          <h2>We'd love to hear from you!</h2>
          <p>Please fill out the form below and we'll get back to you as soon as possible.</p>
          <ContactForm />
        </Col>
      </Row>
      <Row>
        <Col>
          <Map />
        </Col>
      </Row>
      {/* <Footer /> */}
    </div>
  );
};

export default Contact;