import React from 'react';
import { Form, Input, message } from 'antd';
import { Button } from 'react-bootstrap';

const ContactForm = () => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    fetch("https://formspree.io/f/meqwdpeo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(values)
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Error: " + response.statusText);
      }
    })
    .then(responseJson => {
      console.log("Success:", responseJson);
      form.resetFields();
      message.success('Your message has been sent successfully!', 3);
    })
    .catch(error => {
      console.error("Error:", error);
      message.error('An error occurred. Please try again later.', 3);
    });
  };

  return (
    <Form
      form={form}
      name="contact_form"
      onFinish={onFinish}
    >
      <Form.Item
        name="name"
        rules={[
          {
            required: true,
            message: 'Please input your name!',
          },
        ]}
      >
        <Input placeholder="Name" />
      </Form.Item>
      <Form.Item
        name="_replyto"
        rules={[
          {
            required: true,
            message: 'Please input your email!',
          },
          {
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>
      <Form.Item
        name="message"
        rules={[
          {
            required: true,
            message: 'Please input your message!',
          },
        ]}
      >
        <Input.TextArea placeholder="Message" rows={3} />
      </Form.Item>
      <Form.Item style={{ textAlign: 'center' }}>
        <Button variant="success" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ContactForm;